import { AxiosResponse } from 'axios';
import * as workflowTypeAction from './workflow-type.action';
import * as workflowType from './workflow-type.constant';
import { IAction, IWorkflowType } from '@shared-components/models';
import { successNotificationHandler } from '@moxie/shared';
import {
  addCRMWorkflowType,
  deleteCRMWorkflowType,
  getOneCRMWorkflowType,
  getCRMWorkflowTypeList,
  updateCRMWorkflowType,
  getCRMWorkflowTypeByServiceId
} from '../../libs/services.api/lib/workflow-type.api';
import { put, takeLatest } from 'redux-saga/effects';
import { WORKFLOW_TYPE_RESPONSE_MESSAGE } from '@moxie/constants';
import { crmErrorHandler } from 'libs/shared/src/crm-modules/shared';

export const addCRMWorkflowTypeData = function* ({
  payload,
  triggerRefresh,
}: IAction<IWorkflowType>) {
  try {
    if (payload) {
      yield addCRMWorkflowType(payload);
      yield successNotificationHandler(WORKFLOW_TYPE_RESPONSE_MESSAGE.CREATED);
      triggerRefresh && triggerRefresh();
    }
  } catch (error) {
    crmErrorHandler(error);
    yield put(workflowTypeAction.addWorkflowTypeFailure(error));
  }
};

export const updateCRMWorkflowTypeData = function* ({
  payload,
  triggerRefresh,
}: IAction<IWorkflowType>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield updateCRMWorkflowType(payload);
      yield put(workflowTypeAction.updateWorkflowTypeSuccess());
      yield successNotificationHandler(response?.data?.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (error) {
    crmErrorHandler(error);
    yield put(workflowTypeAction.updateWorkflowTypeFailure(error));
  }
};

export const deleteCRMWorkflowTypeData = function* ({
  payload,
  triggerRefresh,
}: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield deleteCRMWorkflowType(payload);
      yield put(workflowTypeAction.deleteWorkflowTypeSuccess());
      yield successNotificationHandler(response?.data?.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (error) {
    crmErrorHandler(error);
    yield put(workflowTypeAction.deleteWorkflowTypeFailure(error));
  }
};

export const getCRMWorkflowTypeData = function* ({ payload }: IAction<string>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getOneCRMWorkflowType(payload);
      yield put(workflowTypeAction.getWorkflowTypeSuccess());
      yield successNotificationHandler(response?.data?.message);
    }
  } catch (error) {
    crmErrorHandler(error);
    yield put(workflowTypeAction.getWorkflowTypeFailure(error));
  }
};

export const getCRMWorkflowTypesListByServiceId = function* ({
  id,
}: IAction<string>) {
  try {
    if (id) {
      const response: AxiosResponse = yield getCRMWorkflowTypeByServiceId(id);
      yield put(
        workflowTypeAction.getWorkflowTypesBYServiceIdSuccess(
          response?.data?.data
        )
      );
    }
  } catch (error) {
    yield put(workflowTypeAction.getWorkflowTypesBYServiceIdFailure(error));
  }
};

export const getCRMWorkflowTypeListData = function* () {
  try {
    const response: AxiosResponse = yield getCRMWorkflowTypeList();
    yield put(workflowTypeAction.getWorkflowTypesSuccess(response?.data?.data));
  } catch (error) {
    crmErrorHandler(error);
    yield put(workflowTypeAction.getWorkflowTypesFailure(error));
  }
};

export default function* crmWorkflowTypeSaga(): unknown {
  yield takeLatest(
    workflowType.ADD_WORKFLOW_TYPE_REQUEST,
    addCRMWorkflowTypeData
  );
  yield takeLatest(
    workflowType.UPDATE_WORKFLOW_TYPE_REQUEST,
    updateCRMWorkflowTypeData
  );
  yield takeLatest(
    workflowType.DELETE_WORKFLOW_TYPE_REQUEST,
    deleteCRMWorkflowTypeData
  );
  yield takeLatest(
    workflowType.GET_WORKFLOW_TYPE_REQUEST,
    getCRMWorkflowTypeData
  );
  yield takeLatest(
    workflowType.GET_WORKFLOW_TYPES_BY_SERVICE_ID_REQUEST,
    getCRMWorkflowTypesListByServiceId
  );
  yield takeLatest(
    workflowType.GET_WORKFLOW_TYPES_REQUEST,
    getCRMWorkflowTypeListData
  );
}
