import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  ADD_OFFICE_VISIT,
  LABEL,
  PAGE_HEADER_MENU_OFFICE_VISIT,
  TEXT
} from '@moxie/constants';
import { CrmTable, PageHeaderTitle } from '@shared-components/elements';
import { OfficeVisitDrawer } from './office-visit-drawer';
import { Button, Space, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { officeVisitActions, useAppSelector } from '@crm/core';
import { PlusOutlined, ShareAltOutlined } from '@ant-design/icons';
import {
  attendingColumns,
  waitingColumns,
  completedColumns,
  archivedColumns,
  pendingColumns,
} from './office-visit-table-column';
import { getBranchesByUserContactPermission, officeVisitList } from '@crm/services.api';
import { OfficeVisitDetailsDrawer } from './office-visit-details-drawer';
import { PageHeaderMenu, SearchBox } from '../../shared';
import { useAssignedBranch } from '@crm/libs/hooks/contacts';
import { capitalizeFirstLetter } from 'libs/shared/src/functions';
import OfficeVisitUrlShareModal from './office-visit-url-share-modal';
import { OfficeVisitApproveModal } from './office-visit-approve-modal';
import { ViewEnquiryDetailsDrawer } from '@crm/src/pages/enquiries/components/view-enquiry/view-enquiry-details-drawer';
import NoteFormProvider from '@crm/src/shared/note/note-form-provider';
import { OfficeVisitContext } from '@crm/src/pages/office-visit/office-visit-provider';
import moment from 'moment';

const getColumns = (name: string) => {
  switch (name) {
    case "pending":
      return pendingColumns;

    case "waiting":
      return waitingColumns;

    case "attending":
      return attendingColumns;

    case "completed":
      return completedColumns;

    case "archived":
      return archivedColumns;

    default:
      return;
  }
}

const OfficeVisitTableComponent: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const officeVisitStatus = name !== 'archived' ? capitalizeFirstLetter(name) : 'Unattended';
  const [form] = useForm();
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showShareModal, setShowShareModal] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [showAll, setShowAll] = useState<boolean>(true);
  const [assignedToMe, setAssignedToMe] = useState<boolean>(false);
  const { selectedBranch, setSelectedBranch } = useContext(OfficeVisitContext);

  const {
    drawerIsOpen,
    detailDrawerIsOpen,
    refreshTable,
    loggedInUserId,
    loggedInUserBranchId,
    isEnquiryDrawerOpen,
    isApproveModalOpen,
    enquiryId,
  } = useAppSelector((store) => ({
    initialData: store.office_visits.singleData,
    drawerIsOpen: store.office_visits.drawerIsOpen,
    detailDrawerIsOpen: store.office_visits.detailDrawerIsOpen,
    refreshTable: store.office_visits.refresh,
    loggedInUserId: store.auth?.user?.id,
    loggedInUserBranchId: store.auth?.user?.branchId as string,
    companyId: store.auth?.user?.companyId,
    isEnquiryDrawerOpen: store.office_visits?.isEnquiryDrawerOpen,
    isApproveModalOpen: store.office_visits?.isApproveModalOpen,
    enquiryId: store.office_visits?.enquiryId,
  }));

  const { branches: branchList } = useAssignedBranch();

  // const socket = useAppSelector((state) => state.socket.wss);
  // const user = useAppSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const triggerRefresh = () => {
    dispatch(officeVisitActions.officeVisitTableRefresh());
  };

  const showDrawer = () => {
    setVisible(true);
    dispatch(officeVisitActions.clearOfficeVisitSingleData());
    form.setFields([{
      name: 'dueDateLabel',
      value: 'tomorrow'
    },
    {
      name: 'dueAt',
      value: moment().add('days', 1)
    }
    ]);
  };

  const handleClose = () => {
    form.resetFields();
    setVisible(false);
    setDisabled(true);
    dispatch(officeVisitActions.clearOfficeVisitSingleData());
    dispatch(officeVisitActions.closeOfficeVisitDrawer());
  };
  const handleEnquiryDrawerClose = () => {
    dispatch(officeVisitActions.closeOfficeEnquiryDrawer());
  }
  const handleDetailsDrawerClose = () => {
    // dispatch(themeActions.toggleNavbar(false));
    dispatch(officeVisitActions.closeOfficeVisitDetailsDrawer());
  };

  const getData = (params: Record<string, unknown>) => {
    return officeVisitList(params);
  };

  const setDrawerOpen = (): boolean => visible || drawerIsOpen!;

  const handleTableButtons = (type: string, isActive: boolean) => {
    switch (type) {
      case 'assignedToMe':
        setAssignedToMe(!assignedToMe);
        setShowAll(isActive);
        setFilter({ ...filter, assigneeId: isActive ? undefined : loggedInUserId });
        return;
      case 'showAll':
        setShowAll(!showAll);
        setAssignedToMe(isActive);
        setFilter({ ...filter, assigneeId: undefined });
        return;
      default:
        return;
    }
  };

  const handleBranchChange = (branchId: string) => {
    setSelectedBranch(branchId);
    setFilter({ ...filter, branchId });
  };

  const setDetailDrawerOpen = () => {
    dispatch(officeVisitActions.openOfficeVisitDetailsDrawer());
  }

  const location = useLocation();

  const modifiedFilter = React.useMemo(() => {
    return {
      ...filter,
      branchId: selectedBranch,
    }
  }, [selectedBranch, filter, officeVisitStatus])

  useEffect(() => {
    if (branchList.length > 0 && branchList.find(b => b.id === loggedInUserBranchId)) {
      const params = new URLSearchParams(location.search.replace('?', ''));
      const preSelectedBranch = params.get('filter.branchId');
      if (preSelectedBranch) {
        setSelectedBranch(preSelectedBranch);
      } else {
        setSelectedBranch(loggedInUserBranchId);
      }
    }
  }, [branchList, loggedInUserBranchId]);

  useEffect(() => {
    if (filter.status !== officeVisitStatus) setFilter({ ...filter });
  }, [officeVisitStatus]);

  if (!selectedBranch) {
    return (
      <div className="page-load">
        <Spin />
      </div>
    )
  }

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.OFFICE_VISIT} />
      <PageHeaderMenu
        menu={PAGE_HEADER_MENU_OFFICE_VISIT}
        defaultActiveKey={name}
      >
        <div className="add-office-visit-btn-container">
          <Space direction='horizontal'>
            <Button type="ghost" onClick={() => setShowShareModal(true)}><ShareAltOutlined />Share</Button>
            <Button type="primary" onClick={showDrawer}>
              <PlusOutlined data-testid="crm-addofficevisitbutton" />{' '}
              {ADD_OFFICE_VISIT}
            </Button>
          </Space>
        </div>
      </PageHeaderMenu>
      <OfficeVisitDrawer
        visible={setDrawerOpen()}
        onClose={handleClose}
        disabled={disabled}
        setDisabled={setDisabled}
        form={form}
      />
      <NoteFormProvider>
        <OfficeVisitDetailsDrawer
          visible={detailDrawerIsOpen!}
          onClose={handleDetailsDrawerClose}
          setDrawerOpen={setDetailDrawerOpen}
        />
      </NoteFormProvider>
      <div className="bg-white padding-top-2">
        <CrmTable
          tableName={TEXT.OFFICE_VISIT}
          key={name}
          action={getData as any}
          filter={modifiedFilter}
          refresh={refreshTable}
          type={officeVisitStatus}
          columns={getColumns(name)}
          className="lead-table"
          filterContent={
            <SearchBox
              fetchQuery={getBranchesByUserContactPermission}
              valueKey='id'
              labelKey='name'
              value={selectedBranch}
              className="branch-list"
              defaultOpen={false}
              showArrow
              placeholder="Select branch"
              onChange={handleBranchChange}
            />
          }
          tableButtons={
            <>
              <Button
                onClick={() => handleTableButtons('assignedToMe', assignedToMe)}
                type={assignedToMe ? 'primary' : 'default'}
              >
                {LABEL.ASSIGNED_TO_ME}
              </Button>
              <Button
                onClick={() => handleTableButtons('showAll', showAll)}
                type={showAll ? 'primary' : 'default'}
              >
                {LABEL.SHOW_ALL}
              </Button>
            </>
          }
        />
      </div>
      <OfficeVisitUrlShareModal key={selectedBranch} showShareModal={showShareModal} onCancel={() => setShowShareModal(false)} selectedBranch={selectedBranch} />
      {enquiryId ?
        <ViewEnquiryDetailsDrawer key={enquiryId} visible={isEnquiryDrawerOpen} handleClose={handleEnquiryDrawerClose} enquiryId={enquiryId} />
        : null}
      <OfficeVisitApproveModal visible={isApproveModalOpen} dispatch={dispatch} triggerRefresh={triggerRefresh} />
    </div>
  );
};

export { OfficeVisitTableComponent };
