import { productActions } from '@crm/core';
import { PRODUCT_RESPONSE_MESSAGE } from '@moxie/constants';
import { successNotificationHandler } from '@moxie/shared';
import { useMutation } from '@tanstack/react-query';
import { updateCRMProduct } from 'apps/crm/src/libs/services.api/lib/product.api';
import { crmErrorHandler } from 'libs/shared/src/crm-modules/shared';
import { useDispatch } from 'react-redux';

export const useUpdateProduct = (
  productId: string | undefined,
  currentPage: any,
  search: any,
  selectedService: any,
  selectedCountry: any
) => {
  const dispatch = useDispatch();
  const mutation = useMutation({
    mutationFn: (data) => updateCRMProduct(productId!, data),
    onSuccess: () => {
      successNotificationHandler(
        `${PRODUCT_RESPONSE_MESSAGE.UPDATE_SUCCESS}`,
        1.5
      );
      dispatch(
        productActions.getProductsRequest({
          page: currentPage,
          s: search,
          service: selectedService,
          country: selectedCountry,
        })
      );
    },
    onError: (error) => {
      crmErrorHandler(error);
    },
  });

  const updateProduct = (data: any) => {
    mutation.mutate(data);
  };
  return {
    isLoading: mutation.isLoading,
    updateProduct,
  };
};
