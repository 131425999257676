import {
  addContactEducationBackground,
  addContactProfessionalExperience,
  archiveLead,
  deleteContactEducationBackground,
  deleteContactProfessionalExperience,
  deleteLead,
  getAllContactProfessionalExperience,
  getContactDetails,
  getContactEducationBackground,
  updateContact,
  updateContactEducationBackground,
  updateContactProfessionalExperience,
  updateCrmContactAssignee,
  updateCrmContactFollowers
} from '@crm/services.api';
import { successNotificationHandler } from '@moxie/shared';
import { IAction, IContactCrm } from '@shared-components/models';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import * as contactAction from './contact.action';
import * as contact from './contact.constant';
import { crmErrorHandler } from 'libs/shared/src/crm-modules/shared';
import * as actionTypes from './contact.action';

import { CLIENT_RESPONSE_MESSAGE, CONTACT_RESPONSE_MESSAGE, EDUCATION_BACKGROUND_RESPONSE_MESSAGE, LEAD_PROFESSIONAL_EXPERIENCE_RESPONSE_MESSAGE } from '@moxie/constants';

// type UpdatePersonalInformation = Required<Pick<IAction<any>,'socket' | 'payload' | 'user'>>

export function* getContactDetailsInfo(payload: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getContactDetails(payload?.payload);
      yield put(contactAction.getContactDetailsSuccess(response?.data));
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(contactAction.getContactDetailsFailure(err));
  }
}

export function* deleteClientData ({
  payload,
  triggerRefresh,
}: IAction<any>) {
  try {
    if (payload) {
      const { id, data } = payload;
      yield deleteLead(id, data);
      successNotificationHandler(CLIENT_RESPONSE_MESSAGE.CLIENT_DELETED);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(actionTypes.deleteClientFailure(err));
  }
}

export function* updateContactData({
  payload,
  triggerRefresh,
}: IAction) {
  try {
    if (payload) {
      const { id } = payload;
      yield updateContact(id, payload);
      yield put(contactAction.getContactDetailsRequest(id));
      yield put(contactAction.updateContactSuccess())
      if (triggerRefresh) {
        triggerRefresh(id);
      }
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(contactAction.updateContactFailure(err));
  }
}

export function* updateContactAssigneeData({
  payload,
  triggerRefresh,
}: IAction) {
  try {
    if (payload) {
      const { id } = payload;
      yield updateCrmContactAssignee(payload);
      yield put(contactAction.getContactDetailsRequest(id));
      yield successNotificationHandler(CONTACT_RESPONSE_MESSAGE.UPDATED);
      if (triggerRefresh) {
        // triggerRefresh(response.data);
        triggerRefresh(id);
      }
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(contactAction.updateContactAssigneeFailure(err));
  }
}

export function* updateContactFollowersData({ payload, triggerRefresh }: any) {
  try {
    if (payload) {
      const id = payload?.id;
      const response: AxiosResponse = yield updateCrmContactFollowers(payload);
      yield put(contactAction.getContactDetailsRequest(id));
      yield successNotificationHandler(CONTACT_RESPONSE_MESSAGE.UPDATED);

      if (triggerRefresh) {
        triggerRefresh(response.data);
      }
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(contactAction.updateContactFollowersFailure(err));
  }
}

export function* updatePersonalInfoData({ payload, triggerRefresh }: any) {
  try {
    if (payload) {
      const id = payload?.id;
      delete payload?.id;
      yield updateContact(id, payload);
      yield put(contactAction.updateContactPersonalInfoSuccess());
      yield put(contactAction.getContactDetailsRequest(id));
      yield successNotificationHandler(CONTACT_RESPONSE_MESSAGE.UPDATED);
      triggerRefresh(id);
    }
  } catch (error) {
    crmErrorHandler(error);
    yield put(contactAction.updateContactPersonalInfoFailure(error));
  }
}

export function* updateAddressInfoData({ payload, triggerRefresh }: any) {
  try {
    if (payload) {
      const id = payload?.id;
      delete payload?.id;
      yield updateContact(id, payload);
      yield put(contactAction.updateContactAddressInfoSuccess());
      yield put(contactAction.getContactDetailsRequest(id));
      yield successNotificationHandler(CONTACT_RESPONSE_MESSAGE.UPDATED);
      triggerRefresh(id);
    }
  } catch (error) {
    crmErrorHandler(error);
    yield put(contactAction.updateContactAddressInfoFailure(error));
  }
}

export function* updatePassportVisaInfo({ payload, triggerRefresh }: any) {
  try {
    if (payload) {
      const id = payload?.id;
      delete payload?.id;
      yield updateContact(id, payload);
      yield put(contactAction.updateContactPassportVisaInfoSuccess());
      yield put(contactAction.getContactDetailsRequest(id));
      yield successNotificationHandler(CONTACT_RESPONSE_MESSAGE.UPDATED);
      triggerRefresh(id);
    }
  } catch (error) {
    crmErrorHandler(error);
    yield put(contactAction.updateContactPassportVisaInfoFailure(error));
  }
}

export function* updateContactEmergencyDetailsInfo({
  payload,
  triggerRefresh,
}: any) {
  try {
    if (payload) {
      const { id, ...rest } = payload;
      yield updateContact(id, rest);
      yield put(contactAction.updateContactEmergencyDetailsSuccess());
      yield put(contactAction.getContactDetailsRequest(id));
      yield successNotificationHandler(CONTACT_RESPONSE_MESSAGE.UPDATED);
      triggerRefresh(id);
    }
  } catch (error) {
    crmErrorHandler(error);
    yield put(contactAction.updateContactEmergencyDetailsFailure(error));
  }
}

export function* getContactEducationBackgroundInfo({ payload }: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getContactEducationBackground(
        payload
      );
      yield put(
        contactAction.getContactEducationBackgroundSuccess(response?.data)
      );
    }
  } catch (error) {
    crmErrorHandler(error);
    yield put(contactAction.getContactEducationBackgroundFailure(error));
  }
}

export function* addContactEducationBackgroundInfo({
  payload,
  triggerRefresh,
}: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield addContactEducationBackground(
        payload
      );
      yield put(
        contactAction.addContactEducationBackgroundSuccess()
      );

      yield successNotificationHandler(EDUCATION_BACKGROUND_RESPONSE_MESSAGE.ADD_EDUCATION_BACKGROUND);
      yield put(contactAction.getContactEducationBackgroundRequest(payload?.contactId));
      triggerRefresh(response.data);
    }
  } catch (error) {
    crmErrorHandler(error);
    yield put(contactAction.addContactEducationBackgroundFailure(error));
  }
}

export function* updateContactEducationBackgroundInfo({
  payload,
  triggerRefresh,
}: any) {
  try {
    if (payload) {
      yield updateContactEducationBackground(payload);
      yield put(contactAction.updateContactEducationBackgroundSuccess());
      yield successNotificationHandler(EDUCATION_BACKGROUND_RESPONSE_MESSAGE.UPDATE_SUCCESS);
      yield put(contactAction.getContactEducationBackgroundRequest(payload?.contactId));
      triggerRefresh(payload?.contactId);
    }
  } catch (error) {
    crmErrorHandler(error);
    yield put(contactAction.updateContactEducationBackgroundFailure(error));
  }
}

export function* deleteContactEducationBackgroundInfo({
  payload,
  triggerRefresh,
}: { payload: { contactId: string; educationBackgroundId: string }, triggerRefresh: (data: any) => void; } | any) {
  try {
    if (payload) {
      const { contactId, educationBackgroundId } = payload;
      yield deleteContactEducationBackground(
        educationBackgroundId
      );
      yield put(contactAction.deleteContactEducationBackgroundSuccess());
      yield successNotificationHandler(EDUCATION_BACKGROUND_RESPONSE_MESSAGE.DELETE_SUCCESS);
      yield put(contactAction.getContactEducationBackgroundRequest(contactId));
      triggerRefresh(contactId);
    }
  } catch (error) {
    crmErrorHandler(error);
    yield put(contactAction.deleteContactEducationBackgroundFailure(error));
  }
}

export function* addContactProfessionalExperienceInfo({
  payload,
  triggerRefresh,
}: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield addContactProfessionalExperience(
        payload
      );
      yield put(contactAction.addContactProfessionalExperienceSuccess());
      yield successNotificationHandler(LEAD_PROFESSIONAL_EXPERIENCE_RESPONSE_MESSAGE.ADD_LEAD_PROFESSIONAL_EXPERIENCE);
      yield put(contactAction.getContactProfessionalExperienceRequest(payload?.contactId));
      triggerRefresh(response.data);
    }
  } catch (error) {
    crmErrorHandler(error);
    yield put(contactAction.addContactProfessionalExperienceFailure(error));
  }
}

export function* getAllContactProfessionalExperienceInfo({ id }: any) {
  try {
    const response: AxiosResponse = yield getAllContactProfessionalExperience(
      id
    );
    yield put(
      contactAction.getContactProfessionalExperienceSuccess(
        response?.data
      )
    );
  } catch (error) {
    crmErrorHandler(error);
    yield put(contactAction.getContactProfessionalExperienceFailure(error));
  }
}

export function* updateContactProfessionalExperienceInfo({
  payload,
  triggerRefresh,
}: any) {
  try {
    yield updateContactProfessionalExperience(
      payload
    );
    yield put(contactAction.updateContactProfessionalExperienceSuccess());
    yield put(contactAction.getContactProfessionalExperienceRequest(payload?.contactId));
    yield successNotificationHandler(LEAD_PROFESSIONAL_EXPERIENCE_RESPONSE_MESSAGE.UPDATE_LEAD_PROFESSIONAL_EXPERIENCE);
    triggerRefresh(payload?.contactId);
  } catch (error) {
    crmErrorHandler(error);
    yield put(contactAction.updateContactProfessionalExperienceFailure(error));
  }
}

export function* deleteContactProfessionalExperienceInfo({
  payload,
  triggerRefresh,
}: any) {
  try {
    const { contactId } = payload;
    yield deleteContactProfessionalExperience(
      payload
    );
    yield put(contactAction.deleteContactProfessionalExperienceSuccess());
    yield put(contactAction.getContactProfessionalExperienceRequest(contactId));
    yield successNotificationHandler(LEAD_PROFESSIONAL_EXPERIENCE_RESPONSE_MESSAGE.LEAD_PROFESSIONAL_EXPERIENCE_DELETED);
    triggerRefresh(contactId);
  } catch (error) {
    crmErrorHandler(error);
    yield put(contactAction.deleteContactProfessionalExperienceFailure(error));
  }
}

export const unarchiveContactData = function*({
  payload,
  triggerRefresh,
}: IAction<any>) {
  try {
    if (payload) {
      yield archiveLead(payload?.id, payload?.data);
      successNotificationHandler(CLIENT_RESPONSE_MESSAGE.CLIENT_UNARCHIVED);
      triggerRefresh && triggerRefresh?.();
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put({ type: actionTypes.unarchiveClientFailure });
  }
};


export const archiveContactData = function*({
  payload,
  triggerRefresh,
}: IAction<any>) {
  try {
    if (payload) {
      yield archiveLead(payload?.id, payload?.data);
      successNotificationHandler(CLIENT_RESPONSE_MESSAGE.CLIENT_ARCHIVED);
      triggerRefresh && triggerRefresh?.();
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put({ type: actionTypes.archiveClientFailure });
  }
};

function* editClient({ payload, triggerRefresh }: IAction<IContactCrm>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield updateContact(payload?.id, payload);
      yield put({ type: actionTypes.editClientSuccess, payload: response.data });
      successNotificationHandler(CLIENT_RESPONSE_MESSAGE.CLIENT_UPDATED);
      triggerRefresh && triggerRefresh(response.data);
    }
  } catch (e) {
    crmErrorHandler(e);
    yield put({ type: actionTypes.editClientFailure(e) });
  }
}

export default function* contactSaga(): unknown {
  yield takeLatest(contact.GET_CONTACT_DETAILS_REQUEST, getContactDetailsInfo);
  yield takeLatest(
    contact.UPDATE_CONTACT_ASSIGNEE_REQUEST,
    updateContactAssigneeData
  );
  yield takeLatest(
    contact.UPDATE_CONTACT_REQUEST,
    updateContactData
  );
  yield takeLatest(
    contact.UPDATE_CONTACT_FOLLOWERS_REQUEST,
    updateContactFollowersData
  );
  yield takeLatest(
    contact.UPDATE_CONTACT_PERSONAL_INFO_REQUEST,
    updatePersonalInfoData
  );
  yield takeLatest(
    contact.UPDATE_CONTACT_ADDRESS_INFO_REQUEST,
    updateAddressInfoData
  );
  yield takeLatest(
    contact.UPDATE_CONTACT_PASSPORT_VISA_INFO_REQUEST,
    updatePassportVisaInfo
  );
  yield takeLatest(
    contact.UPDATE_CONTACT_EMERGENCY_DETAILS_REQUEST,
    updateContactEmergencyDetailsInfo
  );
  yield takeLatest(
    contact.GET_CONTACT_EDUCATION_BACKGROUND_REQUEST,
    getContactEducationBackgroundInfo
  );
  yield takeLatest(
    contact.ADD_CONTACT_EDUCATION_BACKGROUND_REQUEST,
    addContactEducationBackgroundInfo
  );
  yield takeLatest(
    contact.UPDATE_CONTACT_EDUCATION_BACKGROUND_REQUEST,
    updateContactEducationBackgroundInfo
  );
  yield takeLatest(
    contact.DELETE_CONTACT_EDUCATION_BACKGROUND_REQUEST,
    deleteContactEducationBackgroundInfo
  );
  yield takeLatest(
    contact.ADD_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST,
    addContactProfessionalExperienceInfo
  );
  yield takeLatest(
    contact.GET_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST,
    getAllContactProfessionalExperienceInfo
  );
  yield takeLatest(
    contact.UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST,
    updateContactProfessionalExperienceInfo
  );
  yield takeLatest(
    contact.DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST,
    deleteContactProfessionalExperienceInfo
  );

  yield takeLatest(contact.ARCHIVE_CLIENT_REQUEST, archiveContactData);
  yield takeLatest(contact.UNARCHIVE_CLIENT_REQUEST, unarchiveContactData);

  yield takeLatest(contact.UPDATE_CLIENT_REQUEST, editClient);
  yield takeLatest(contact.DELETE_CLIENT_REQUEST, deleteClientData);


}
