import { postOfficeBranch, updateOfficeBranch } from "@crm/services.api";
import { IOfficeBranchCRM } from "@model/branch";
import { useMutation } from "@tanstack/react-query";

export const useOfficeBranchMutation = () => {
  return useMutation({
    mutationKey: ['add-office-branch'],
    mutationFn: (data: IOfficeBranchCRM) => postOfficeBranch(data),
  })
}

export const useOfficeBranchUpdate = () => {
  return useMutation({
    mutationKey: ['update-office-branch'],
    mutationFn: (data: Partial<IOfficeBranchCRM>) => updateOfficeBranch(data),
  })
}
