import { officeVisitUpdate } from "@crm/services.api"
import { IOfficeVisitCrm } from "@model/index";
import { CONTACT_VISIT_RESPONSE_MESSAGE } from "@moxie/constants";
import { errorNotificationHandler, successNotificationHandler } from "@moxie/shared";
import { useMutation, useQueryClient } from "@tanstack/react-query"

export const useOfficeVisitUpdate = (officeVisitId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: Partial<IOfficeVisitCrm>) => await officeVisitUpdate(officeVisitId, body),
    onSuccess: (data) => {
      successNotificationHandler(CONTACT_VISIT_RESPONSE_MESSAGE.UPDATED);
      queryClient.invalidateQueries({
        queryKey: ['contact-office-visits'],
      })
    },
    onError: () => {
      errorNotificationHandler('Error updating office visit.')
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['update-contact-office-visits', officeVisitId],
      })
    },
  })
}
