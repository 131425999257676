import { getProductTypeByServiceId } from "@crm/libs/services.api/lib/product-type.api";
import { useQuery } from "@tanstack/react-query";

const useProductTypes = (selectedService: string | undefined) => {
  const { data, isLoading } = useQuery({
    queryKey: ['productTypes', selectedService],
    enabled: !!selectedService,
    queryFn: () => getProductTypeByServiceId(selectedService!),
  });

  return {
    productTypes: data ?? [],
    isLoading,
  }
}

export default useProductTypes;
