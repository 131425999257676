import { fetchUserNotifications } from "@crm/services.api"
import { CrmPaginatedResponse } from "@model/api-response.model"
import { Notification } from "@model/notification"
import { errorNotificationHandler } from "@moxie/shared"
import { useInfiniteQuery } from "@tanstack/react-query"

export const useFetchNotifications = (limit: number) => {
  const query = useInfiniteQuery<CrmPaginatedResponse<Notification>>({
    queryFn: (args) => fetchUserNotifications(args.pageParam, limit),
    queryKey: ['notifications', limit],
    getNextPageParam: (lastPage) => lastPage.data.meta.currentPage === lastPage.data.meta.totalPages ? false : Number(lastPage.data.meta.currentPage) + 1,
    onError() {
      errorNotificationHandler('Error in fetching notifications')
    },
  });

  return {
    notifications: query.data?.pages?.map(p => p.data.data)?.flat() ?? [],
    hasMore: Boolean(query.hasNextPage),
    next: query.fetchNextPage,
    currentPage: (query.data?.pages?.slice(-1)[0])?.data.meta.currentPage ?? 1,
    isLoading: query.isLoading,
  }
}
