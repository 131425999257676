import React from 'react';
import Spin from 'antd/es/spin';
import { useGetSubjectDiscipline } from '../hooks/useGetSubjectDiscipline';
import Tree, { DataNode, TreeProps } from 'antd/lib/tree';

interface Props {
  onChange: (vals: string[]) => void,
  selectedSubjectArea: string[],
}
const DisciplineFilter: React.FC<Props> = ({ selectedSubjectArea, onChange }) => {

  const {
    subjectDisciplines,
    subjectDisciplinesLoading,
  } = useGetSubjectDiscipline();

  const disciplineTree: DataNode[] = subjectDisciplines.map((discipline): DataNode => ({
    title: discipline.discipline,
    key: discipline.id,
    children: discipline.subjectAreas?.map(subjectArea => ({ key: subjectArea.name, title: subjectArea.name })) ?? [],
  }))

  const onCheck: TreeProps['onCheck'] = (values) => {
    if (Array.isArray(values)) {
      const subTypes = values.filter(val => typeof val === 'string') as string[];
      onChange(subTypes)
    }
  }

  if (subjectDisciplinesLoading) {
    return <Spin />
  }

  return (
    <Tree
      checkable
      multiple
      selectable={false}
      checkedKeys={selectedSubjectArea}
      treeData={disciplineTree}
      onCheck={onCheck}
    />
  )
}

export default DisciplineFilter
