import { IAction, IContactCrm, IPayload, IResponse } from '@shared-components/models';
import {
  ADD_LEAD_FAILURE,
  ADD_LEAD_REQUEST,
  ADD_LEAD_SUCCESS,
  ARCHIVE_LEAD_REQUEST,
  ARCHIVE_LEAD_SUCCESS,
  CLEAR_LEAD,
  DELETE_LEAD_FAILURE,
  DELETE_LEAD_REQUEST,
  DELETE_LEAD_SUCCESS,
  DOWNLOADING,
  UNARCHIVE_LEAD_FAILURE,
  UNARCHIVE_LEAD_REQUEST,
  UNARCHIVE_LEAD_SUCCESS,
  UPDATE_LEAD_FAILURE,
  UPDATE_LEAD_REQUEST,
  UPDATE_LEAD_SUCCESS
} from './lead.constant';

export const addLeadRequest = (
  data: IContactCrm,
  triggerRefresh: (res: IResponse<IContactCrm>) => void
): IAction<IContactCrm> => ({
  type: ADD_LEAD_REQUEST,
  payload: data,
  triggerRefresh: triggerRefresh,
});

export const addLeadSuccess = (payload: IContactCrm): IAction<IContactCrm> => ({
  type: ADD_LEAD_SUCCESS,
  payload,
});

export const addLeadFailure = (payload: any): IAction => ({
  type: ADD_LEAD_FAILURE,
  payload,
});

export const editLeadAction = (
  payload: IContactCrm,
  triggerRefresh: any
): IAction<IContactCrm> => ({
  type: UPDATE_LEAD_REQUEST,
  payload,
  triggerRefresh,
});

export const editLeadSuccess = (payload: IContactCrm): IAction<IContactCrm> => ({
  type: UPDATE_LEAD_SUCCESS,
  payload,
});

export const editLeadFailure = (payload: any): IAction => ({
  type: UPDATE_LEAD_FAILURE,
  payload,
});

export const archiveLeadRequest = (
  id: string,
  data: any,
  triggerRefresh: () => void
): IAction<any> => ({
  type: ARCHIVE_LEAD_REQUEST,
  payload: { id, data },
  triggerRefresh,
});

export const unarchiveLeadRequest = (
  id: string,
  data: any,
  triggerRefresh: () => void
): IAction<any> => ({
  type: UNARCHIVE_LEAD_REQUEST,
  payload: { id, data },
  triggerRefresh,
});

export const archiveLeadSuccess = (payload: IPayload<any>): IAction => ({
  type: ARCHIVE_LEAD_SUCCESS,
  payload,
});

export const archiveLeadFailure = (payload: IPayload<any>): IAction => ({
  type: DELETE_LEAD_FAILURE,
  payload,
});


export const unarchiveLeadSuccess = (payload: IPayload<any>): IAction => ({
  type: UNARCHIVE_LEAD_SUCCESS,
  payload,
});

export const unarchiveLeadFailure = (payload: IPayload<any>): IAction => ({
  type: UNARCHIVE_LEAD_FAILURE,
  payload,
});

export const deleteLeadRequest = (
  id: string,
  data: { isDeleted: boolean; deletedBy: string; contactId: string },
  triggerRefresh: () => void
): IAction<any> => ({
  type: DELETE_LEAD_REQUEST,
  payload: { id, data },
  triggerRefresh,
});

export const deleteLeadSuccess = (payload: IPayload<any>): IAction => ({
  type: DELETE_LEAD_SUCCESS,
  payload,
});

export const deleteLeadFailure = (payload: IPayload<any>): IAction => ({
  type: DELETE_LEAD_FAILURE,
  payload,
});

export const downloading = (payload: boolean): IAction<boolean> => ({
  type: DOWNLOADING,
  payload,
})

export const clearLeads = (): IAction<string> => ({
  type: CLEAR_LEAD,
});
