import {
  GET_INSTITUTION_REQUEST,
  GET_INSTITUTION_SUCCESS,
  GET_INSTITUTION_FAILURE,
  CLEAR_INSTITUTION_FETCH,
  ADD_INSTITUTION_REQUEST,
  ADD_INSTITUTION_SUCCESS,
  ADD_INSTITUTION_FAILURE,
  UPDATE_INSTITUTION_REQUEST,
  UPDATE_INSTITUTION_SUCCESS,
  UPDATE_INSTITUTION_FAILURE,
  DELETE_INSTITUTION_REQUEST,
  DELETE_INSTITUTION_SUCCESS,
  DELETE_INSTITUTION_FAILURE,
  SET_SELECTED_INSTITUTION,
  IMPORT_INSTITUTION_REQUEST,
  IMPORT_INSTITUTION_SUCCESS,
  IMPORT_INSTITUTION_FAILURE,
  GET_INSTITUTION_BY_SERVICE_REQUEST,
  GET_INSTITUTION_BY_SERVICE_SUCCESS,
  GET_INSTITUTION_BY_SERVICE_FAILURE
} from './institution.constant';
import {
  IAction,
  IInstitutionDetail,
  IInstitutionPayload,
  IPayload
} from '@shared-components/models';

export const getInstitutionRequest = (id: number): IAction<number> => ({
  type: GET_INSTITUTION_REQUEST,
  payload: id,
});

export const getInstitutionSuccess = (
  payload: IInstitutionDetail
): IAction<IInstitutionDetail> => ({
  type: GET_INSTITUTION_SUCCESS,
  payload,
});

export const getInstitutionFailure = (
  payload: IInstitutionPayload
): IAction => ({
  type: GET_INSTITUTION_FAILURE,
  payload,
});

export const getInstitutionByServiceRequest = (
  id: string
): IAction<Record<string, unknown>> => ({
  type: GET_INSTITUTION_BY_SERVICE_REQUEST,
  payload: { serviceId: id },
});

export const getInstitutionByServiceSuccess = (
  payload: IInstitutionDetail
): IAction<IInstitutionDetail> => ({
  type: GET_INSTITUTION_BY_SERVICE_SUCCESS,
  payload,
});

export const getInstitutionByServiceFailure = (
  payload: IInstitutionPayload
): IAction => ({
  type: GET_INSTITUTION_BY_SERVICE_FAILURE,
  payload,
});

export const addInstitutionRequest = (
  data: IInstitutionDetail,
  triggerRefresh: () => void
): IAction<IInstitutionDetail> => ({
  type: ADD_INSTITUTION_REQUEST,
  payload: data,
  triggerRefresh: triggerRefresh,
});

export const addInstitutionSuccess = (
  payload: IInstitutionDetail
): IAction<IInstitutionDetail> => ({
  type: ADD_INSTITUTION_SUCCESS,
  payload,
});

export const addInstitutionFailure = (
  payload: IInstitutionPayload
): IAction => ({
  type: ADD_INSTITUTION_FAILURE,
  payload,
});

export const updateInstitutionRequest = (
  data: IInstitutionDetail,
  triggerRefresh?: () => void,
  clearFetchInstitution?: boolean
): IAction<IInstitutionDetail> => ({
  type: UPDATE_INSTITUTION_REQUEST,
  payload: data,
  triggerRefresh: triggerRefresh,
  clearFetchInstitution: clearFetchInstitution,
});

export const updateInstitutionSuccess = (
  payload: IInstitutionDetail
): IAction<IInstitutionDetail> => ({
  type: UPDATE_INSTITUTION_SUCCESS,
  payload,
});

export const updateInstitutionFailure = (
  payload: IInstitutionPayload
): IAction => ({
  type: UPDATE_INSTITUTION_FAILURE,
  payload,
});

export const deleteInstitutionRequest = (
  id: number,
  triggerRefresh: any
): IAction<any> => ({
  type: DELETE_INSTITUTION_REQUEST,
  payload: id,
  triggerRefresh,
});

export const deleteInstitutionSuccess = (payload: any): IAction => ({
  type: DELETE_INSTITUTION_SUCCESS,
  payload,
});

export const deleteInstitutionFailure = (
  payload: IPayload<IInstitutionDetail>
): IAction => ({
  type: DELETE_INSTITUTION_FAILURE,
  payload,
});

export const importInstitutionRequest = (
  data: string[],
  triggerRefresh: any
): IAction<any> => ({
  type: IMPORT_INSTITUTION_REQUEST,
  payload: data,
  triggerRefresh,
});

export const importInstitutionSuccess = (payload: any): IAction => ({
  type: IMPORT_INSTITUTION_SUCCESS,
  payload,
});

export const importInstitutionFailure = (payload: any): IAction => ({
  type: IMPORT_INSTITUTION_FAILURE,
  payload,
});

export const clearFetchInstitution = (): IAction<string> => ({
  type: CLEAR_INSTITUTION_FETCH,
});

export const setSelectedInstitution = (payload: IPayload<string[]>) => ({
  type: SET_SELECTED_INSTITUTION,
  payload,
});
