import { getOneOfficeBranch } from "@crm/services.api";
import { errorNotificationHandler } from "@moxie/shared";
import { useQuery } from "@tanstack/react-query";

export const useFetchBrancheById = (id: string) => {
  const { data, isLoading } = useQuery({
    queryKey: ['branch-by-id',id],
    queryFn: () => getOneOfficeBranch(id),
    onError: () => errorNotificationHandler('Error fetching branch'),
  });

  return {
    branch: data,
    isLoading,
  }
}
