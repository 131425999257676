import Avatar from 'antd/es/avatar';
import React from 'react';
import { Col } from 'antd/es/grid';
import Typography from 'antd/es/typography';
import Space, { SpaceProps } from 'antd/es/space';
import UserOutlined from '@ant-design/icons/UserOutlined';
import { classNames, UNASSIGNED_LABEL } from '@moxie/constants';

const UnassignedCard: React.FC<SpaceProps> = ({ className, ...props }) => (
  <Space className={classNames('align-items-center cursor-pointer', className)} {...props}>
    <Col>
      <Avatar icon={<UserOutlined />} alt="unassigned" />
    </Col>
    <Col>
      <Typography.Text type="secondary">{UNASSIGNED_LABEL}</Typography.Text>
    </Col>
  </Space>
);


export default UnassignedCard;
