import { IAction } from '@shared-components/models';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { productTypeActions } from '..';
import * as productTypes from './product-type.constant';
import {
  getProductSubTypes
} from '../../libs/services.api/lib/product-type.api';
import { getOneService } from '@crm/services.api';

export const getAllProductTypeByServiceId = function* ({ id }: IAction<string>) {
  try {
    if (id) {
      const response: AxiosResponse = yield getOneService(id);
      yield put(
        productTypeActions.getProductTypeByServiceIdSuccess(response?.data?.data?.productTypes ?? [])
      );
    }
  } catch (error) {
    yield put(productTypeActions.getProductTypeByServiceIdFailure(error));
  }
};

export const getAllProductSubTypes = function* ({ id }: IAction<string>) {
  try {
    if (id) {
      const response: AxiosResponse = yield getProductSubTypes(id);
      yield put(
        productTypeActions.getProductSubTypesSuccess(response?.data?.data)
      );
    }
  } catch (error) {
    yield put(productTypeActions.getProductSubTypesFailure(error));
  }
};

export default function* productTypeByServiceIdSaga(): unknown {
  yield takeLatest(
    productTypes.FETCH_PRODUCT_TYPE_BY_SERVICE_ID_REQUEST,
    getAllProductTypeByServiceId
  );
  yield takeLatest(
    productTypes.FETCH_PRODUCT_SUB_TYPES_REQUEST,
    getAllProductSubTypes
  );
}
