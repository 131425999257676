import { ServiceList } from '@crm/src/shared/interested-service-tree/service-list-card';
import { Col, Collapse, Row } from 'antd'
import moment from 'moment'
import React from 'react'

const { Panel } = Collapse;

type Props = {
  name: string,
  createdAt: string,
  data: any,
}


export const EnquiryInterestedServicesActivity: React.FC<Props> = ({
  name,
  createdAt,
  data,
}) => {
  return (
    <>
      <Collapse
        defaultActiveKey={[]}
        expandIconPosition={'left'}
        accordion={true}
      >
        <Panel
          header={(
            <div className="enquiry-info-interested-services" style={{ position: "absolute", top: "7px", left: "3%" }} >
              <div className="enquiry-author"> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Interested Service</span> changed</div>
              <div className="enquiry-date" style={{ position: "absolute", right: "3%", top: "50%", transform: "translate(-15px,-50%)" }}>
                <div>{moment(createdAt).format('dddd, MMMM D, YYYY')}</div>
                <div>by {name}</div>
              </div>
            </div>
          )}
          key="1">
          <Row gutter={24} align="middle">
            <Col span={6}>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <ServiceList currentService={{
                  parent: data.previousInterestedServices.typeOfService,
                  countries: data.previousInterestedServices.countries,
                  interests: data.previousInterestedServices.interests,
                  type: data.previousInterestedServices.type ?? '',
                }} />
              </div>
            </Col>

            <Col span={6}>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                changed to
              </div>
            </Col>

            <Col span={6}>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <ServiceList currentService={{
                  parent: data.interestedServices.typeOfService,
                  countries: data.interestedServices.countries,
                  interests: data.interestedServices.interests,
                  type: data.interestedServices.type ?? ''
                }} />
              </div>
            </Col>
          </Row>
        </Panel >
      </Collapse >
    </>
  );
}
