import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Mention from '@tiptap/extension-mention';
import Underline from '@tiptap/extension-underline';
import suggestion from './suggestion';
import Highlight from '@tiptap/extension-highlight';
import Typography from '@tiptap/extension-typography'


export const getExtensions = (type?: string) => {
  const baseExtension = [
    StarterKit.configure({
      history: {
        depth: 50,
      },
    }),
    Underline,
    Highlight,
    Typography,
  ];

  if(type !== 'enquiry'){
    baseExtension.push(
      Mention.configure({
        HTMLAttributes: {
          class: 'tiptap-mention',
        },
        suggestion,
      }) as any
    );
  }

  return baseExtension;
}

const useTipTapEditor = ( content?: string , type?: string ) => {
  const editor = useEditor({
    content,
    extensions : getExtensions(type),
    editorProps: {
      attributes: {
        class: 'tiptap-editor',
      },
    },
  });

  return editor;
}


export default useTipTapEditor;
