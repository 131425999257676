import React from 'react';
import {
  BarChartOutlined,
  UserOutlined,
  ContactsOutlined,
  PartitionOutlined,
  ReadOutlined,
  BankOutlined,
  TeamOutlined,
  BranchesOutlined,
  QuestionCircleOutlined,
  AuditOutlined,
  BlockOutlined
} from '@ant-design/icons';
// import { ProspectOutlinedIcon } from '../../../../../libs/shared/src/elements/icons/libs/prospect';
import { ClientOutlinedIcon } from '../../../../../libs/shared/src/elements/icons/libs/client';
import { LeadOutlinedIcon } from '../../../../../libs/shared/src/elements/icons/libs/lead';
import {
  CONTACTS,
  LABEL,
  LEAD_TITLE,
  OFFICE_TITLE,
  ORGANIZATION_TITLE,
  REPORT_TITLE,
  TEXT,
  URL_ALL_CLIENTS,
  URL_ALL_CONTACTS,
  URL_ALL_LEAD,
  URL_CONTACT_REPORT,
  URL_APPLICATION_REPORT,
  // URL_ALL_PROSPECTS,
  URL_DASHBOARD,
  URL_ENQUIRIES_NEW_CONTACTS,
  URL_INSTITUTE,
  URL_OFFICE,
  URL_PRODUCTS,
  URL_SERVICE,
  URL_SUB_AGENTS,
  URL_USER_ACTIVE,
  USER_TITLE,
  URL_DEALS_SIDEBAR,
  URL_OFFICE_VISIT_PENDING,
  URL_APPLICATIONS_SIDEBAR
} from '@moxie/constants';
// import { ProspectOutlinedIcon } from '@shared-components/elements';

export const routes: any = {
  route: {
    path: URL_DASHBOARD,
    layout: false,
    routes: [
      {
        path: URL_DASHBOARD,
        exact: true,
        name: TEXT.DASHBOARD,
        icon: <BarChartOutlined />,
      },
      {
        path: URL_ENQUIRIES_NEW_CONTACTS,
        name: TEXT.ENQUIRIES,
        exact: true,
        icon: <QuestionCircleOutlined />,
      },
      {
        name: TEXT.CONTACTS,
        collapsed: true,
        flatMenu: true,
        routes: [
          {
            exact: true,
            name: TEXT.CONTACTS,
            path: URL_ALL_CONTACTS,
            icon: <TeamOutlined />,
          },
          {
            path: URL_ALL_LEAD,
            exact: true,
            name: (
              <span className="crm-sidebar-sub-menu-text">{LEAD_TITLE}</span>
            ),
            icon: (
              <span className="crm-sidebar-sub-menu-icon">
                <LeadOutlinedIcon />
              </span>
            ),
          },
          {
            path: URL_ALL_CLIENTS,
            exact: true,
            name: (
              <span className="crm-sidebar-sub-menu-text">{TEXT.CLIENTS}</span>
            ),
            icon: (
              <span className="crm-sidebar-sub-menu-icon">
                <ClientOutlinedIcon />
              </span>
            ),
          },
        ],
      },
      {
        path: URL_APPLICATIONS_SIDEBAR,
        name: TEXT.APPLICATIONS,
        exact: true,
        icon: <BlockOutlined />,
      },
      {
        path: URL_DEALS_SIDEBAR,
        name: TEXT.DEALS,
        exact: true,
        icon: <ReadOutlined />,
      },
      {
        path: `${URL_OFFICE_VISIT_PENDING}`,
        exact: true,
        name: TEXT.OFFICE_VISIT,
        icon: <ContactsOutlined />,
      },
      {
        path: URL_INSTITUTE,
        exact: true,
        name: TEXT.INSTITUTE,
        icon: <BankOutlined />,
      },
      {
        path: URL_PRODUCTS,
        exact: true,
        name: TEXT.PRODUCTS,
        icon: <ReadOutlined />,
      },
      {
        path: URL_SERVICE,
        exact: true,
        name: TEXT.SERVICES,
        icon: <PartitionOutlined />,
      },
      {
        path: URL_SUB_AGENTS,
        exact: true,
        name: TEXT.AGENTS,
        icon: <BranchesOutlined />,
      },
      // {
      //   path: '/calendar',
      //   exact: true,
      //   name: 'Calendar',
      //   icon: <CalendarOutlined />,
      // },
      {
        name: ORGANIZATION_TITLE,
        icon: <UserOutlined />,
        routes: [
          {
            path: URL_OFFICE,
            exact: true,
            name: OFFICE_TITLE,
          },
          {
            path: `${URL_USER_ACTIVE}`,
            exact: true,
            name: USER_TITLE,
          },
        ],
      },
      {
        name: REPORT_TITLE,
        icon: <AuditOutlined />,
        routes: [
          {
            path: URL_CONTACT_REPORT,
            exact: true,
            name: CONTACTS,
          },
          {
            path: URL_APPLICATION_REPORT,
            exact: true,
            name: LABEL.APPLICATIONS,
          },
        ],
      },
    ],
  },
};
