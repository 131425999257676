
import { saveInstitution, updateInstitution } from "@crm/services.api";
import { ICrmInstitution } from "@model/crm/institution.model";
import { useMutation } from "@tanstack/react-query";

export const useInstitutionMutation = () => {
  return useMutation({
    mutationKey: ['add-institution'],
    mutationFn: (data: ICrmInstitution) => saveInstitution(data),
  })
}

export const useInstitutionUpdate = () => {
  return useMutation({
    mutationKey: ['update-institution'],
    mutationFn: (data: Partial<ICrmInstitution>) => updateInstitution(data),
  })
}
