import { contactActions } from "@crm/core";
import { updateBranches } from "@crm/services.api";
import { errorNotificationHandler, successNotificationHandler } from "@moxie/shared";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

interface IBranchData {
    id: string;
}

export const useUpdateBranchMutation = (contactId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (branches: IBranchData[]) => updateBranches(contactId, branches),
    onSuccess() {
      successNotificationHandler('Branches updated successfully.')
      queryClient.invalidateQueries({
        queryKey:['contact-branches',contactId],
      });
      dispatch(contactActions.getContactDetailsRequest(contactId));
      queryClient.invalidateQueries({
        queryKey:['contact-assignable-users',contactId],
      });
    },
    onError() {
      errorNotificationHandler('Failed to update branches');
    },
  })
}
