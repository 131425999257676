import { Tooltip } from 'antd';
import React from 'react';

export const ProductIntakeComponent = ({ intakes }: {intakes: string[]}) => {
  const displayMonths = intakes?.slice(0, 2);
  const additionalMonthsNumber = intakes?.length - 2;
  const additionalMonths = intakes?.slice(2);

  if (intakes?.length === 0) {
    return <span>N/A</span>;
  }
  return (
    <>
      {intakes
        ? displayMonths?.map((intake: string, i: number) => (
          <React.Fragment key={intake}>
            <p className="initial_capital">
              {i > 0 && ', '}
              {intake?.slice(0, 3)}&nbsp;
            </p>
          </React.Fragment>
        ))
        : 'N/A'}
      {intakes
        ? additionalMonthsNumber > 0 && (
          <Tooltip
            title={
              <p className="initial_capital">
                {additionalMonths?.join(', ')}
              </p>
            }
          >
            <span>
              {additionalMonthsNumber >= 1
                ? `+${additionalMonthsNumber} More`
                : ''}
            </span>
          </Tooltip>
        )
        : ''}
    </>
  );
};
