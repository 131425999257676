import { API_URL } from "@moxie/constants"
import crmAxios from "./crm-api-axios"
import { DocsCount, FindDocumentParams, Media, MediaPayload, UpdateMedia, UploadExistingDocsPayload, FindDocumentCountParams } from '@model/document';

export const findDocumentCount = async (params: FindDocumentCountParams) => {
  const { data } = await crmAxios.get<{ data: DocsCount[] }>(`${API_URL.CRM_MEDIA}/group-by`, {
    params,
  })
  return data.data
}


export const findAllMediaBySubjectIdAndMediaType = async (params: FindDocumentParams) => {
  const { data } = await crmAxios.get<{ data: Media[] }>(API_URL.CRM_MEDIA, { params })
  return data;
}

export const addMedia = async (payload: MediaPayload) => {
  const formData = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    formData.append(key, value)
  });
  return crmAxios.post(API_URL.CRM_MEDIA, formData);
}

export const getMediaUrl = async (mediaId: string) => {
  const { data } = await crmAxios.get<{ data: string }>(`${API_URL.CRM_MEDIA}/${mediaId}`)
  return data.data
}

export const uploadExistingDocs = (docs: UploadExistingDocsPayload[]) => {
  return crmAxios.post(`${API_URL.CRM_MEDIA}/upload-existing-docs`, { docs })
}

export const updateMedia = async (mediaId: string, payload: UpdateMedia) => {
  const { data } = await crmAxios.patch(`${API_URL.CRM_MEDIA}/${mediaId}`, payload);
  return data;
}

interface DownloadDocsQuery {
  ids?: string[];
  subjectId?: string;
  stageId?: string;
}
export const downloadAllStageDocs = async (params: DownloadDocsQuery) => {
  const { data } = await crmAxios.get<{ data: string }>(`${API_URL.CRM_MEDIA}/download-all`, {
    params,
  })

  return data.data
}
