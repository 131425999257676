import { errorHandler } from '@moxie/utils';
import { IAction } from '@shared-components/models';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import {
  getAllDocumentType,
  getAllDocuments
} from '../../libs/services.api/lib/contact-documents.api';
import * as action from './document.action';
import * as actionTypes from './document.constant';

const getDocument = function* ({ id }: IAction<any>) {
  try {
    if (id) {
      const res: AxiosResponse = yield getAllDocuments(id);
      yield put(action.getDocumentSuccess(res?.data?.data));
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.getDocumentFailure(err));
  }
};

const getDocumentType = function* ({ id, payload }: IAction<any>) {
  try {
    if (id) {
      const res: AxiosResponse = yield getAllDocumentType(id, payload);
      yield put(action.getDocumentTypeSuccess(res?.data?.data));
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.getDocumentTypeFailure(err));
  }
};

export default function* rootSaga(): unknown {
  yield takeLatest(actionTypes.GET_DOCUMENT_REQUEST, getDocument);
  yield takeLatest(actionTypes.GET_DOCUMENT_TYPE_REQUEST, getDocumentType);
}
