import {
  IAction,
  IOfficeVisitCrm,
  Prettify
} from '@shared-components/models';
import * as actionType from './office-visit.constant';

export const OFFICE_VISIT_FEATURE_KEY = 'office_visits';

interface IInitialState<T> {
  allData: T[];
  singleData: Partial<T>;
  error: boolean | null;
  loading: boolean;
  drawerIsOpen: boolean;
  detailDrawerIsOpen: boolean;
  isApproveModalOpen: boolean;
  isEnquiryDrawerOpen: boolean;
  refresh: boolean;
  prevVisits: T[];
  enquiryId: string | undefined;
}

export const initialOfficeVisitState: Prettify<IInitialState<IOfficeVisitCrm>> = {
  allData: [],
  singleData: {},
  error: null,
  loading: false,
  drawerIsOpen: false,
  detailDrawerIsOpen: false,
  isApproveModalOpen: false,
  isEnquiryDrawerOpen: false,
  refresh: false,
  prevVisits: [],
  enquiryId: undefined,
};

const officeVisitReducer = (
  state = initialOfficeVisitState,
  action: IAction<any>
): IInitialState<IOfficeVisitCrm> => {
  switch (action.type) {
    case actionType.ADD_OFFICE_VISIT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionType.ADD_OFFICE_VISIT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.ADD_OFFICE_VISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionType.GET_OFFICE_VISIT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionType.GET_OFFICE_VISIT_SUCCESS:
      return {
        ...state,
        loading: false,
        singleData: action.payload,
      };
    case actionType.GET_OFFICE_VISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionType.UPDATE_OFFICE_VISIT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionType.UPDATE_OFFICE_VISIT_SUCCESS:
      return {
        ...state,
      };
    case actionType.UPDATE_OFFICE_VISIT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case actionType.CLEAR_OFFICE_VISIT_DATA:
      return {
        ...state,
        singleData: {},
      };
    case actionType.OPEN_OFFICE_VISIT_DRAWER:
      return {
        ...state,
        drawerIsOpen: true,
      };
    case actionType.CLOSE_OFFICE_VISIT_DRAWER:
      return {
        ...state,
        drawerIsOpen: false,
      };
    case actionType.OPEN_OFFICE_VISIT_DETAILS_DRAWER:
      return {
        ...state,
        detailDrawerIsOpen: true,
      };
    case actionType.CLOSE_OFFICE_VISIT_DETAILS_DRAWER:
      return {
        ...state,
        detailDrawerIsOpen: false,
      };
    case actionType.OPEN_OFFICE_VISIT_ENQUIRY_DRAWER:
      return {
        ...state,
        isEnquiryDrawerOpen: true,
        enquiryId: action.payload.enquiryId,
      };
    case actionType.CLOSE_OFFICE_VISIT_ENQUIRY_DRAWER:
      return {
        ...state,
        isEnquiryDrawerOpen: false,
        enquiryId: undefined,
      };
    case actionType.OPEN_APPROVE_MODAL:
      return {
        ...state,
        isApproveModalOpen: true,
      };
    case actionType.CLOSE_APPROVE_MODAL:
      return {
        ...state,
        isApproveModalOpen: false,
      };
    case actionType.OFFICE_VISIT_TABLE_REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case actionType.GET_PREV_OFFICE_VISIT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionType.GET_PREV_OFFICE_VISIT_SUCCESS:
      return {
        ...state,
        loading: false,
        prevVisits: action.payload,
      };
    case actionType.GET_PREV_OFFICE_VISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export { officeVisitReducer };
