import React, { useRef, useState } from 'react';
import { Button, Checkbox, Input, Popover } from 'antd';
import { useSearchBox } from '@crm/libs/hooks';
import { queryAssignees } from '@crm/services.api';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UserNameCard } from 'libs/shared/src/crm-modules/shared';
import Spin from 'antd/es/spin';
import LoadingOutlined from '@ant-design/icons/Loading3QuartersOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import Empty from 'antd/es/empty';
import { arrayToMap } from '@crm/libs/helpers';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import UnassignedCard from '../user-card/unassigned-card';
import { classNames } from '@moxie/constants';

const Loader = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface Props {
  onReset?: () => void;
  assignees: string[];
  onChange: (value: string | null | string[]) => void;
  isMultiSelect?: boolean;
  hideClear?: boolean;
  showUnassigned?: boolean,
  isEnquirySearch?: boolean;
  filter?: Record<string, unknown>;
}

const UserPopoverSearch: React.FC<React.PropsWithChildren<Props>> = ({
  onReset,
  assignees,
  onChange,
  isMultiSelect = false,
  children,
  hideClear,
  showUnassigned = false,
  isEnquirySearch,
  filter,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const searchRef = useRef<Input>(null)

  const { onSearch, options, fetchNextPage, hasMorePage, isLoading } = useSearchBox({
    fetchQuery: (params) => queryAssignees({ ...params, limit: 5 }),
    search: search,
    setSearch: setSearch,
    valueKey: 'id',
    value: assignees.filter(item => item !== 'null'),
    enabled: isVisible,
    filter,
  });

  const onClear = () => {
    onReset && onReset();
  }

  const handleChange = (event: CheckboxChangeEvent, value: string) => {
    let selectedAssignees = assignees.filter(userId => userId !== value);
    if (event.target.checked) {
      selectedAssignees = Array.from(new Set([...assignees, value]))
    }
    onChange(selectedAssignees);
  }

  // autofocus search box as input gets focused only once when `autofocus` props was used
  if (searchRef.current) {
    searchRef.current.focus()
  }

  const assigneeMap = arrayToMap(assignees)
  return (
    <Popover
      placement="bottom"
      trigger={["click"]}
      onVisibleChange={(visibility) => {
        setIsVisible(visibility);
        setSearchInput('');
      }}
      content={
        <div className="table-filter__popover-content">
          <div>
            <Input.Search
              value={searchInput}
              allowClear
              placeholder="Search assignees"
              onChange={(e) => {
                setSearchInput(e.target.value);
                onSearch(e.target.value);
              }}
              ref={searchRef}
            />
            {showUnassigned && (
              <div className="padding-top-1">
                <UnassignedCard onClick={() => onChange(isMultiSelect ? [] : null)} />
              </div>
            )}
          </div>

          <div id="assignee-scroll" style={{ marginLeft: "-16px" }}>
            <InfiniteScroll
              dataLength={options.length}
              hasMore={hasMorePage}
              next={() => fetchNextPage()}
              scrollableTarget="assignee-scroll"
              loader={isLoading ? <div style={{ 'textAlign': 'center' }}><Spin indicator={Loader} /></div> : null}
            >
              {
                isEnquirySearch && (<Checkbox checked={assigneeMap.has('null')} onChange={(e) => handleChange(e, 'null')}>
                  <UserNameCard
                    firstName={"Unassigned"}
                    lastName={""}
                    email={""}
                  />
                </Checkbox>)
              }

              {options.map(user => (
                <div key={user.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {isMultiSelect ?
                    (<Checkbox checked={assigneeMap.has(user.id)} onChange={(e) => handleChange(e, user.id)}>
                      <UserNameCard
                        firstName={user.firstName}
                        lastName={user.lastName}
                        email={user.email}
                      />
                    </Checkbox>)
                    :
                    (
                      <div
                        className={classNames("single-select-item", assignees[0] === user.id ? "single-select-item-selected" : "")}
                        onClick={() => onChange([user.id])}
                      >
                        <UserNameCard
                          firstName={user.firstName}
                          lastName={user.lastName}
                          email={user.email}
                        />
                      </div>
                    )
                  }
                </div>
              ))}
              {!options.length && <Empty />}
            </InfiniteScroll>
          </div>
          {!hideClear && (<div className="text-align-right">
            <Button size="small" icon={<CloseOutlined />} onClick={onClear}>
              Clear
            </Button>
          </div>)}
        </div >
      }>
      {children}
    </Popover >

  )
}
export default UserPopoverSearch;
