import { AxiosResponse } from 'axios';
import { IAction, IWorkflow } from '@shared-components/models';
import { put, takeLatest } from 'redux-saga/effects';
import * as workflow from './workflow.constant';
import * as workflowAction from './workflow.action';
import { successNotificationHandler } from '@moxie/shared';
import {
  addCRMWorkflow,
  addWorkflowStage,
  deleteCRMWorkflow,
  deleteWorkflowStageChecklist,
  getAllWorkflows,
  getOneCRMWorkflow,
  getWfChecklists,
  getWorkflowStages
} from '../../libs/services.api/lib/workflow.api';
import { getWorkflowsByInstitution } from '../../libs/services.api/lib';
import { APPLICATION_STAGE_RESPONSE_MESSAGE, CHECKLIST_RESPONSE_MESSAGE } from '@moxie/constants';
import { crmErrorHandler } from 'libs/shared/src/crm-modules/shared';
import { getChecklists } from '@crm/services.api';

export const getAllWorkflowsList = function* () {
  try {
    const response: AxiosResponse = yield getAllWorkflows();
    yield put(workflowAction.getAllWorkflowsSuccess(response?.data?.data));
  } catch (error) {
    crmErrorHandler(error);
    yield put(workflowAction.getAllWorkflowsFailure(error));
  }
};

export const getOneCRMWorkflowData = function* ({ id }: IAction<string>) {
  try {
    if (id) {
      const response: AxiosResponse = yield getOneCRMWorkflow(id);
      yield put(workflowAction.getCRMWorkflowSuccess(response?.data?.data));
    }
  } catch (error) {
    yield put(workflowAction.getCRMWorkflowFailure(error));
  }
};

export const getAllWorkflowsByInstitutionId = function* ({
  id,
}: IAction<string>) {
  try {
    if (id) {
      const response: AxiosResponse = yield getWorkflowsByInstitution(id);
      yield put(
        workflowAction.getCRMWorkflowsByInstitutionIdSuccess(
          response?.data
        )
      );
    }
  } catch (error) {
    yield put(workflowAction.getCRMWorkflowsByInstitutionIdFailure(error));
  }
};

export const addCRMWorkflowData = function* ({
  payload,
  triggerRefresh,
}: IAction<IWorkflow>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield addCRMWorkflow(payload);
      yield put(workflowAction.addCRMWorkflowSuccess(response?.data?.data));
      triggerRefresh && triggerRefresh();
    }
  } catch (error) {
    yield put(workflowAction.addCRMWorkflowFailure(error));
  }
};

export const deleteCRMWorkflowData = function* ({
  payload,
  triggerRefresh,
}: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield deleteCRMWorkflow(payload);
      yield put(workflowAction.deleteCRMWorkflowSuccess());
      yield successNotificationHandler(response?.data?.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (error) {
    crmErrorHandler(error);
    yield put(workflowAction.deleteCRMWorkflowFailure(error));
  }
};

export const addWorkflowStageData = function* ({ payload }: IAction) {
  try {
    if (payload) {
      const response: AxiosResponse = yield addWorkflowStage(payload);
      yield put(workflowAction.addWorkflowStageSuccess(response?.data?.data));
      yield successNotificationHandler(APPLICATION_STAGE_RESPONSE_MESSAGE.APPLICATION_STAGE_CREATE);
    }
  } catch (error) {
    crmErrorHandler(error);
    yield put(workflowAction.addWorkflowStageFailure(error));
  }
};

export const getAllWorkflowStages = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getWorkflowStages(payload);
      yield put(workflowAction.getWorkflowStagesSuccess(response?.data?.data));
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(workflowAction.getWorkflowStagesFailure(err));
  }
};

export const getAllWfStageChecklists = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getWfChecklists(payload);
      yield put(
        workflowAction.getWfStageChecklistsSuccess(response?.data?.data)
      );
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(workflowAction.getWfStageChecklistsFailure(err));
  }
};

export const deleteStageChecklist = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      yield deleteWorkflowStageChecklist(payload.id);
      yield put(workflowAction.getWfStageChecklists(payload.stageId));
      successNotificationHandler(CHECKLIST_RESPONSE_MESSAGE.CHECKLIST_DELETE);
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(workflowAction.deleteStageChecklistFailure(err));
  }
};

export const getAllChecklistData = function* (): any {
  try {
    const response: AxiosResponse = yield getChecklists();
    yield put(workflowAction.getAllChecklistsSuccess(response.data.data));
  } catch (err) {
    yield put(workflowAction.getAllChecklistsFailure(err));
  }
};

export default function* crmWorkflowSaga(): unknown {
  yield takeLatest(workflow.ADD_WORKFLOW_REQUEST, addCRMWorkflowData);
  yield takeLatest(workflow.DELETE_WORKFLOW_REQUEST, deleteCRMWorkflowData);
  yield takeLatest(workflow.FETCH_WORKFLOW_REQUEST, getOneCRMWorkflowData);
  yield takeLatest(workflow.FETCH_WORKFLOWS_REQUEST, getAllWorkflowsList);
  yield takeLatest(
    workflow.FETCH_WORKFLOWS_BY_INSTITUTION_ID_REQUEST,
    getAllWorkflowsByInstitutionId
  );
  yield takeLatest(workflow.ADD_WORKFLOW_STAGE_REQUEST, addWorkflowStageData);
  yield takeLatest(
    workflow.FETCH_WORKFLOW_STAGES_REQUEST,
    getAllWorkflowStages
  );
  yield takeLatest(
    workflow.FETCH_WORKFLOW_STAGE_CHECKLISTS_REQUEST,
    getAllWfStageChecklists
  );
  yield takeLatest(
    workflow.DELETE_WF_STAGE_CHECKLIST_REQUEST,
    deleteStageChecklist
  );
  yield takeLatest(workflow.FETCH_CHECKLISTS_REQUEST, getAllChecklistData);
}
