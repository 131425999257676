import { getOfficeList, postOfficeBranch, getOneOfficeBranch, updateOfficeBranch, deleteOfficeBranch } from '@crm/services.api';
import { successNotificationHandler } from '@moxie/shared';
import { IAction } from '@shared-components/models';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import * as action from './office-branch.action';
import * as actionTypes from './office-branch.constant';
import { BRANCH_RESPONSE_MESSAGE } from '@moxie/constants';
import { crmErrorHandler } from 'libs/shared/src/crm-modules/shared';
import { IOfficeBranchCRM } from '@model/branch';

export const getAllOfficeBranches = function* (): any {
  try {
    const response: AxiosResponse = yield getOfficeList({});
    yield put(action.getAllSuccess(response?.data?.data));
  } catch (err) {
    crmErrorHandler(err);
    yield put(action.getAllFailure(err));
  }
};

export const getOfficeBranch = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getOneOfficeBranch(payload);
      yield put(action.getOneSuccess(response.data.data));
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(action.getOneFailure(err));
  }
};

export const addOfficeBranch = function* ({
  payload,
  triggerRefresh,
}: IAction<IOfficeBranchCRM>) {
  try {
    if (payload) {
      yield postOfficeBranch(payload);
      successNotificationHandler(BRANCH_RESPONSE_MESSAGE.BRANCH_GENERATE);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(action.addFailure(err));
  }
};

export const editOfficeBranch = function* ({
  payload,
  triggerRefresh,
}: IAction<IOfficeBranchCRM>) {
  try {
    if (payload) {
      const res: IOfficeBranchCRM = yield updateOfficeBranch(payload);
      yield put(action.updateSuccess(res));
      successNotificationHandler(BRANCH_RESPONSE_MESSAGE.BRANCH_UPDATE);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(action.updateFailure(err));
  }
};

export const deleteOfficeBranchData = function* ({
  payload,
  triggerRefresh,
}: IAction<any>) {
  try {
    if (payload) {
      yield deleteOfficeBranch(payload);
      successNotificationHandler(BRANCH_RESPONSE_MESSAGE.BRANCH_DELETE);
      triggerRefresh && triggerRefresh();
    }
  } catch (err: any) {
    crmErrorHandler(err);
    yield put(action.deleteOfficeBranchFailure(err));
  }
}

const officeBranchSaga = function* (): unknown {
  yield takeLatest(actionTypes.GET_BRANCH_REQUEST, getOfficeBranch);
  yield takeLatest(actionTypes.GET_BRANCHES_REQUEST, getAllOfficeBranches);
  yield takeLatest(actionTypes.ADD_BRANCH_REQUEST, addOfficeBranch);
  yield takeLatest(actionTypes.UPDATE_BRANCH_REQUEST, editOfficeBranch);
  yield takeLatest(actionTypes.DELETE_BRANCH_REQUEST, deleteOfficeBranchData);
};

export default officeBranchSaga;
