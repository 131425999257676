import { UserData } from "@crm/services.api"
import { errorNotificationHandler } from "@moxie/shared"
import { useQuery } from "@tanstack/react-query"

export const useFetchUsersById = (id: string) => {
  const { data, isLoading } = useQuery({
    queryKey: ['fetch-users-by-id', id],
    queryFn: () => UserData(id as string),
    onError: () => errorNotificationHandler('Error fetching users.'),
  })
  return {
    users: data?.data?.data ?? [],
    isLoading,
  }
}
