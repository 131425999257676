import { useAppSelector } from '@crm/core';
import { FormInstance } from 'antd';
import { useMemo, useState } from 'react';

export const useGetProductSubTypes = (form: FormInstance<any>) => {
  const [selectedProductType, setSelectedProductType] = useState<
    string | any
  >();
  const productTypes = useAppSelector((state) => state.product_type.allData);
  const productTypesLoading = useAppSelector(
    (state) => state.product_type.loading
  );

  const handleProductTypeChange = (value: string) => {
    setSelectedProductType(value);
    form.setFieldsValue({ productSubTypeId: undefined });
  };


  const productSubTypes = useMemo(() => {
    return productTypes.find((productType: any) => productType.id === selectedProductType)
  }, [selectedProductType, productTypesLoading]);

  return {
    productSubTypes : productSubTypes?.subTypeLevel ?? [],
    productSubTypesLoading: productTypesLoading,
    selectedProductType,
    setSelectedProductType,
    handleProductTypeChange,
  };
};
