import { fetchNotificationsCount } from "@crm/services.api"
import { useQuery } from "@tanstack/react-query"

export const useFetchUnReadNotificationCount = () => {

  const query = useQuery({
    queryKey: ['notification-count'],
    queryFn: () => fetchNotificationsCount(),
  })

  return {
    count: query.data ?? 0,
  }
}
