import { CrmPaginatedResponse } from "@model/api-response.model";
import crmAxios from "./crm-api-axios"
import { CreateNotePayload, Note, NoteType, UpdateNotePayload } from '@model/crm/note.model';
import { API_URL } from "@moxie/constants";

export const getPaginatedNotes = async (params: Record<string, unknown>, type?: NoteType) => {
  const { data } = await crmAxios.get<CrmPaginatedResponse<Note>>(`${API_URL.CRM_NOTE}`, {
    params: {
      ...params,
      type,
    },
  })
  return data
}

export const createNote = async (type: NoteType, refId: string, body: CreateNotePayload) => crmAxios.post(`${API_URL.CRM_NOTE}/${type}/${refId}`, body);

export const updateNote = (noteId: string, body: Omit<UpdateNotePayload, 'noteId'>) => crmAxios.patch(`${API_URL.CRM_NOTE}/${noteId}`, body);

export const deleteNote = (noteId: string, deleteTask: boolean) => crmAxios.post(`${API_URL.CRM_NOTE}/${noteId}/delete`, { deleteTask });

