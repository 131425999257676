import { Divider, Empty, Spin, Timeline } from 'antd';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TEXT } from '@moxie/constants';
import { OfficeVisitActivity } from '@model/office-visit';
import { useFetchOfficeVisitActivities } from '../hook/useFetchOfficeVisitActivities';

const OfficeVisitActivityLog = ({ id, initialData }: any) => {
  const { activities, hasMore, next, isLoading } = useFetchOfficeVisitActivities(id)

  if (activities && activities?.length <= 0) {
    return (
      <div className="full-height-activities-content ">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );
  }

  return (
    <div id="scrollableDiv" className='office-visit-activities'>
      <InfiniteScroll
        hasMore={hasMore}
        next={() => next()}
        dataLength={activities?.length || 0}
        loader={isLoading ? <Spin /> : null}
        scrollableTarget="scrollableDiv"
        endMessage={
          <Divider plain>You are all caught up</Divider>
        }
        style={{ 'overflowY': 'hidden' }}
      >
        {activities && activities.length === 0 ? <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={TEXT.NO_DATA}
        /> : <Timeline className="margin-top-1">
          {activities && activities
            .filter((item: OfficeVisitActivity) => item.actionType !== 'created')
            .map((item: OfficeVisitActivity) => (
              <Timeline.Item key={item?.id}>
                <OfficeVisitActivityItem activities={item} key={item?.id} />
              </Timeline.Item>
            ))}
        </Timeline>}
      </InfiniteScroll>
    </div >
  );
};

export { OfficeVisitActivityLog };

interface IOfficeVisitActivitiesItem {
  activities?: any;
  key?: any;
}

const OfficeVisitActivityItem = (props: IOfficeVisitActivitiesItem) => {
  const { activities } = props;
  const { activity, actionType, createdAt, user } = activities;

  const userName = user?.firstName + ' ' + user?.lastName;

  const getInformation = (): string | any => {
    if (actionType === 'started' && activity === 'office visit') {
      return (
        <>
          The session has been started{' '}
          <div className="ant-comment-content-author-name time-weight-size">
            by {userName}, {moment().from(moment(createdAt), true)} ago
          </div>
        </>
      );
    } else if (actionType === 'completed' && activity === 'office visit') {
      return (
        <>
          The session has been completed{' '}
          <div className="ant-comment-content-author-name time-weight-size">
            by {userName}, {moment().from(moment(createdAt), true)} ago
          </div>
        </>
      );
    } else if (actionType === 'updated' && activity === 'assignee') {
      return (
        <>
          Assignee has been updated{' '}
          <div className="ant-comment-content-author-name time-weight-size">
            by {userName}, {moment().from(moment(createdAt), true)} ago
          </div>
        </>
      );
    } else if (actionType === 'updated' && activity === 'session note') {
      return (
        <>
          Session notes have been updated{' '}
          <div className="ant-comment-content-author-name time-weight-size">
            by {userName}, {moment().from(moment(createdAt), true)} ago
          </div>
        </>
      );
    }
  };

  return (
    <div>
      <h4 className="margin-0">{getInformation()}</h4>
    </div>
  );
};
