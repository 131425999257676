import * as actionTypes from './workflow-type.constant';
import {
  IAction,
  IInitialState,
  IWorkflowType
} from '@shared-components/models';

const initialState: IInitialState<IWorkflowType> = {
  allData: [],
  singleData: {},
  error: null,
  loading: false,
};

export const WORKFLOW_TYPE_FEATURE_KEY = 'workflow_types';

const workflowTypeReducer = (state = initialState, action: IAction<any>) => {
  switch (action.type) {
    case actionTypes.ADD_WORKFLOW_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_WORKFLOW_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: [action.payload, ...state.allData],
      };
    case actionTypes.ADD_WORKFLOW_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_WORKFLOW_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_WORKFLOW_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_WORKFLOW_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_WORKFLOW_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.DELETE_WORKFLOW_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.DELETE_WORKFLOW_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_WORKFLOW_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_WORKFLOW_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: action.payload,
      };
    case actionTypes.GET_WORKFLOW_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_WORKFLOW_TYPE_FETCH:
      return {
        ...state,
        singleData: null,
      };
    case actionTypes.GET_WORKFLOW_TYPES_BY_SERVICE_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_WORKFLOW_TYPES_BY_SERVICE_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: action.payload,
      };
    case actionTypes.GET_WORKFLOW_TYPES_BY_SERVICE_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_WORKFLOW_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_WORKFLOW_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: action.payload,
      };
    case actionTypes.GET_WORKFLOW_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export { workflowTypeReducer };
