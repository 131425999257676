import { createSlice } from '@reduxjs/toolkit';
import { IGoogle } from '@shared-components/models';
export const GOOGLE_FEATURE_KEY = 'google';

export interface GoogleState {
  gis: IGoogle | null;
  gisInitied: boolean;
  error: any;
}

export const initialGoogleState: GoogleState = {
  gis: null,
  gisInitied: false,
  error: null,
};

export const googleSlice = createSlice({
  name: GOOGLE_FEATURE_KEY,
  initialState: initialGoogleState,
  reducers: {
    setGis: (state, action) => {
      state.gis = action.payload;
    },
    removeGis: (state) => {
      state.gis = null;
    },
  },
});

export const googleReducer = googleSlice.reducer;

export const googleActions = googleSlice.actions;
