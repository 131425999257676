import { Task } from '@model/crm/note.model';
import Tooltip from 'antd/es/tooltip';
import Typography from 'antd/es/typography';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  task: Task
}
const TaskTitle: React.FC<Props> = ({ task }) => {

  const history = useHistory();
  if (task?.note?.officeVisit) {
    const officeVisit = task?.note?.officeVisit;
    const contact = officeVisit.contact;
    const name = `${contact.firstName} ${contact.lastName}`;
    return (
      <Typography.Title level={5} >
        <span className="initial_capital">{task.type} For&nbsp;</span>
        <Tooltip title={<OfficeVisitTooltip contactName={name} visitDate={moment(officeVisit.createdAt).format('DD/MM/YYYY')} />} >
          <span className="text-primary" onClick={(event) => {
            event.stopPropagation();
            history.push(`/contact/detail/${contact.id}/office-visits`)
          }}>
            Office Visit:&nbsp;{name}
          </span>
        </Tooltip>
      </Typography.Title>
    )
  }

  if (task.note?.deal) {
    const deal = task?.note?.deal;
    const contact = deal.contact;
    const contactName = `${contact.firstName} ${contact.lastName}`;
    return (
      <Typography.Title level={5}>
        <span className="initial_capital">{task.type} For</span>
        <Tooltip title={<DealTooltip contactName={contactName} dealName={deal.name} />} >
          <span className="text-primary" onClick={(event) => {
            event.stopPropagation();
            history.push(`/contact/${contact.id}/deals/${deal.id}/activities/notes`)
          }}>  Deal:&nbsp;{deal.name}</span>
        </Tooltip>
      </Typography.Title>
    )
  }

  if (task.note?.enquiry) {
    const enquiry = task?.note?.enquiry;
    const enquiryContact = enquiry.personalInformation;
    const contactName = `${enquiryContact.firstName} ${enquiryContact.lastName}`;
    return (
      <Typography.Title level={5}>
        <span className="initial_capital">{task.type} For</span>
        <Tooltip title={<EnquiryTooltip contactName={contactName} startedDate={moment(enquiry.createdAt).format('DD/MM/YYYY')} />} >
          <span className="text-primary" onClick={(event) => {
            event.stopPropagation();
            history.push(`/enquiries/all?enquiryId=${enquiry.id}`)
          }}>  Enquiry:&nbsp;{contactName}</span>
        </Tooltip>
      </Typography.Title >
    )
  }

  return null;
}

interface OfficeVisitProps {
  contactName: string;
  visitDate: string;
}
const OfficeVisitTooltip: React.FC<OfficeVisitProps> = ({ contactName, visitDate }) => {
  return (
    <Typography.Text style={{ 'color': "#fff" }}  >
      <span><b>Contact Name: </b>{contactName}</span>
      <br />
      <span><b>Visit Date: </b> {visitDate}</span>
    </Typography.Text>
  )
}

interface DealTooltipProps {
  contactName: string;
  dealName: string;
}

const DealTooltip: React.FC<DealTooltipProps> = ({ contactName, dealName }) => {
  return (
    <Typography.Text style={{ 'color': "#fff" }} >
      <span><b>Deal Name:</b> {dealName}</span>
      <br />
      <span><b>Contact Name:</b> {contactName}</span>
    </Typography.Text>
  )
}

interface EnquiryTooltipProps {
  contactName: string;
  startedDate: string;
}
const EnquiryTooltip: React.FC<EnquiryTooltipProps> = ({ contactName, startedDate }) => {
  return (
    <Typography.Text style={{ 'color': "#fff" }} >
      <span><b>Contact Name:</b> {contactName}</span>
      <br />
      <span><b>Enquiry started : {startedDate}</b></span>
    </Typography.Text>
  )
}
export default TaskTitle
