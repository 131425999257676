import { subjectDisciplineActions, useAppSelector } from '@crm/core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useGetSubjectDiscipline = () => {
  const dispatch = useDispatch();
  const subjectDisciplines = useAppSelector(
    (state) => state.subject_discipline.allData
  );
  const subjectDisciplinesLoading = useAppSelector(
    (state) => state.subject_discipline.loading
  );

  useEffect(() => {
    dispatch(subjectDisciplineActions.getSubjectDisciplineRequest());
  }, []);

  return {
    subjectDisciplines,
    subjectDisciplinesLoading,
  };
};
