import React from 'react';
import { Avatar, Tooltip } from 'antd';
import { generateAvatarNameChars } from '@crm/libs/helpers';

interface Props {
  userList: { firstName: string, lastName: string }[];
}

const UserAvatarGroup: React.FC<Props> = ({
  userList,
}) => (
  <Avatar.Group
    size="default"
    maxCount={3}
    maxStyle={{ backgroundColor: '#4a2362' }}
    className="crm-avatar-group"
  >
    {userList.map((user, idx: number) => {
      return (
        <Tooltip key={idx} title={`${user.firstName} ${user.lastName}`}>
          <Avatar>
            {generateAvatarNameChars({
              firstName: user.firstName,
              lastName: user.lastName,
            })}
          </Avatar>
        </Tooltip>
      );
    })}
  </Avatar.Group>
);

export { UserAvatarGroup };
