import React, { useState } from 'react';
import Badge from 'antd/es/badge';
import Space from 'antd/es/space';
import { Row } from 'antd/es/grid';
import Button from 'antd/es/button';
import Typography from 'antd/es/typography';
import DownOutlined from '@ant-design/icons/DownOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { UserPopoverSearch } from '@crm/src/shared/search';
import { NoteType, TaskPayload } from '@model/crm/note.model';
import { useCreateNote, usePaginateNotes } from './hooks';
import Empty from 'antd/es/empty';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spin from 'antd/es/spin';
import { Divider, Timeline } from 'antd';
import { TEXT } from '@moxie/constants';
import { groupByMonth } from '@crm/libs/helpers';
import NoteModal from './note-modal';
import NoteCard from './note-card';
import NoteFormProvider from './note-form-provider';

interface Props {
  contactId?: string;
  refId: string;
  type: NoteType;
}
type NoteMode = 'create' | 'edit' | undefined;

const Notes: React.FC<Props> = ({ refId, type, contactId }) => {

  const [noteMode, setNoteMode] = useState<NoteMode>();
  const [assignees, setAssignees] = useState<string[]>([]);

  const { hasMore, isLoading, next, notes } = usePaginateNotes({
    type,
    refId,
    assignees,
  });
  const normalizedNotes = groupByMonth(notes);
  const createNoteMutation = useCreateNote(type, refId);

  const createNote = async (description: string, assignees: string[], values?: TaskPayload) => {
    await createNoteMutation.mutateAsync({
      assignees,
      description: description,
      ...(values && { task: values }),
    });
    setNoteMode(undefined);
  }

  return (
    <>
      <div id="notesScrollable" style={{ justifyContent: 'center' }} className={`margin-top-0 ${type === 'enquiry' ? 'padding-left-0 margin-bottom-3' : 'padding-left-3 scrollable-list'}`}>
        <InfiniteScroll
          height={650}
          hasMore={Boolean(hasMore)}
          next={() => next()}
          dataLength={notes.length}
          loader={isLoading ? <Spin /> : null}
          scrollableTarget="notesScrollable"
          endMessage={<Divider plain>You are all caught up</Divider>}
        >
          {notes.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={TEXT.NO_DATA}
            />
          ) : (
            <Timeline className="margin-top-1">
              {Object.entries(normalizedNotes).map(([month, notes]) => (
                <React.Fragment key={month}>
                  <div className="deal-date">
                    {month}
                  </div>
                  {notes.map((item) => (
                    <NoteFormProvider
                      key={item.id}
                    >
                      <NoteCard
                        key={item.id}
                        createdBy={item.createdBy}
                        description={item.description}
                        createdAt={item.createdAt}
                        noteId={item.id}
                        task={item.task}
                        assignees={item.assignees.map(user => user.id)}
                        contactId={contactId}
                        type={type}
                      />
                    </NoteFormProvider>
                  ))}
                </React.Fragment>
              ))}
            </Timeline>
          )}
        </InfiniteScroll>
      </div>
      <div className="tab-filter" style={{ display: 'inline-flex' }}>
        {type !== 'enquiry' && (
          <UserPopoverSearch
            isMultiSelect
            assignees={assignees}
            onReset={() => setAssignees([])}
            onChange={(value) => {
              if (Array.isArray(value)) {
                setAssignees(value);
              }
            }}
          >
            <Row>
              <Space>
                <Typography.Text strong>Activities from :</Typography.Text>
                <Button
                  type="text"
                  size="small"
                  icon={
                    <Badge className="table-filter__badge"
                      count={assignees.length}
                      style={{ marginRight: '10px' }}
                    />
                  }
                >
                  Assignee <DownOutlined />
                </Button>
              </Space>
            </Row>
          </UserPopoverSearch>
        )}
        <div className="action-btn">
          <Button icon={<PlusOutlined />} type="primary" onClick={() => setNoteMode('create')}>
            Create Note
          </Button>
        </div>
      </div>
      <NoteFormProvider>
        <NoteModal
          visible={noteMode === 'create'}
          onClose={() => setNoteMode(undefined)}
          title="Create Note"
          onSubmit={createNote}
          contactId={contactId}
        />
      </NoteFormProvider>
    </>
  )
}

export default Notes;
