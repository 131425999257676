import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import cookies from 'js-cookie';
import { handleTokens, unauthorizedAccess } from '@moxie/utils';
import {
  API_URL,
  AUTH_MESSAGE,
  CRM_COOKIE_ACCESS,
  COOKIE_REFRESH,
  JWT_TOKEN_HEADER,
  CRM_COOKIE_REFRESH
} from '@moxie/constants';
import { authActions, store } from '@lyra/core';
import { errorNotificationHandler } from '@moxie/shared';

const axiosConfig: AxiosRequestConfig = {
  baseURL: process.env.NX_BASE_URL,
};
const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.request.use((config) => {
  const accessToken = cookies.get(CRM_COOKIE_ACCESS);
  if (accessToken && accessToken.length > 10) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err?.message === 'Network Error') {
      errorNotificationHandler(AUTH_MESSAGE.SERVICE_DOWN);
      throw err;
    }

    return new Promise((resolve, reject) => {
      const originalReq = err.config;
      if (
        err?.response?.status === 401 &&
        (!err?.response?.subStatusCode ||
          err?.response?.subStatusCode !== 463) &&
        cookies.get(CRM_COOKIE_ACCESS) &&
        err.config &&
        !err.config.__isRetryRequest
      ) {
        const config: RequestInit = {
          method: 'POST',
          cache: 'no-cache',
          headers: {
            'content-type': 'application/json',
            authorization: `${JWT_TOKEN_HEADER} ${cookies.get(
              CRM_COOKIE_ACCESS
            )}`,
          },
          body: JSON.stringify({
            token: cookies.get(COOKIE_REFRESH),
          }),
        };
        originalReq.__isRetryRequest = true;

        const refreshToken = fetch(
          `${process.env.NX_BASE_URL}${API_URL.REFRESH_TOKEN}`,
          config
        )
          .then((res) => res.json())
          .then((res): AxiosResponse | AxiosPromise | void => {
            if (res.statusCode === 401 && res.subStatusCode === 463) {
              store.dispatch(authActions.openSessionModal());
              reject(err);
            } else if (res.statusCode === 401) {
              handleUnauthorizedAccess();
            } else {
              handleTokens({ data: res });
              return axiosInstance(originalReq);
            }
          });
        resolve(refreshToken);
      } else if (err?.response?.status === 401) {
        handleUnauthorizedAccess();
      }
      return reject(err);
    });
  }
);

const handleUnauthorizedAccess = () => {
  const accessToken = cookies.get(CRM_COOKIE_ACCESS);
  unauthorizedAccess(CRM_COOKIE_ACCESS, CRM_COOKIE_REFRESH);
  store.dispatch(authActions.logout(accessToken));
};

export { axiosInstance };
export default axiosInstance;
