import { SLUG_ACTIVITY, SLUG_NOTES } from "@moxie/constants";
import { Avatar, Card, Tabs, Tag } from "antd";
import { Notes } from "@crm/src/shared/note";
import useContactEnquiriesActivities from "@crm/src/pages/contact-details/sections/details/components/hooks/use-contact-enquiries-activities";
import { EnquiryActivityView } from "../enquiry-activity-view";
import { useParams } from "react-router-dom";


const { TabPane } = Tabs;

interface IEnquiryActivityProps {
  enquiryId: string;
  enquiryData: any;
}
export const EnquiryFormActivities = ({ enquiryId, enquiryData }: IEnquiryActivityProps) => {


  const { activities, hasMore, next, isLoading: isContactEnquiryLoading } = useContactEnquiriesActivities(enquiryId);
  const contactId = useParams<{ contactId: string }>();

  if (!activities || !enquiryData) {
    return null;
  }


  const tablePanels = () => {
    return [
      {
        key: 'activity',
        label: 'Activity',
        url: SLUG_ACTIVITY,
        panel: <EnquiryActivityView
          activities={activities}
          isLoading={isContactEnquiryLoading}
          hasMore={hasMore}
          next={next} />,
      },
      {
        key: 'notes',
        label: 'Notes',
        url: SLUG_NOTES,
        panel: <Notes refId={enquiryId} type="enquiry" contactId={contactId.contactId} />,
      }
    ];
  }


  return (
    <>
      <Card style={{ width: '100%', borderBottom: '1px solid #f0f0f0', marginBottom: 16 }}>
        <div className="justify-content-center">
          <Avatar style={{ marginRight: 16 }}>
            {enquiryData.personalInformation.firstName?.substring(0, 1).toUpperCase()}
            {enquiryData.personalInformation.lastName?.substring(0, 1).toUpperCase()}
          </Avatar>
          <div>
            <div className="justify-content-center">
              <span style={{ fontWeight: '900', fontSize: '1.2rem' }} className="initial_capital">{enquiryData.personalInformation.firstName}  {enquiryData.personalInformation.lastName}</span>
              <Tag color="red" style={{ marginLeft: 8, backgroundColor: '#FAFAFA' }}>{enquiryData.branch?.name ?? 'Unassigned'}</Tag>
            </div>
            <div style={{ marginTop: 2, color: '#8c8c8c' }}>{enquiryData.personalInformation.email}</div>
          </div>
        </div>
      </Card >
      <div id="enquiry-activities">
        <Tabs type="card">
          {tablePanels().map((tab) => {
            return (
              <TabPane tab={tab.label} key={tab.key} active={true}>
                {tab.panel}
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </>

  );
};
