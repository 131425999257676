import {
  institutionBranchesActions,
  useAppSelector,
  workflowActions
} from '@crm/core';
import { FormInstance } from 'antd';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ProductFormStateContext } from '../provider/product-form-state-provider';

export const useGetBranchesByInstitution = (form: FormInstance<any>) => {
  const { selectedInstitution, setSelectedInstitution } = useContext(
    ProductFormStateContext
  );
  const dispatch = useDispatch();
  const branches = useAppSelector(
    (state: any) => state.branches_by_institution.institutionsBranches
  );

  const branchesLoading = useAppSelector(
    (state: any) => state.institutions.branchesLoading
  );
  const institutionWorkflows = useAppSelector((state: any) => state.workflows.allData);
  const institutionWorkflowsLoading = useAppSelector((state: any) => state.workflows.loading);

  const handleInstitutionChange = (value: string) => {
    setSelectedInstitution(value);
    form.setFieldsValue({ branches: undefined, workflows: undefined });
    dispatch(
      institutionBranchesActions.getBranchesByInstitutionRequest(
        value
      )
    );
    dispatch(workflowActions.getCRMWorkflowsByInstitutionIdRequest(value));
  };

  useEffect(() => {
    if (selectedInstitution) {
      dispatch(
        institutionBranchesActions.getBranchesByInstitutionRequest(
          selectedInstitution
        )
      );
      dispatch(workflowActions.getCRMWorkflowsByInstitutionIdRequest(selectedInstitution));
    }
  }, [selectedInstitution]);

  return {
    branches: branches ?? [],
    branchesLoading,
    institutionWorkflows,
    institutionWorkflowsLoading,
    selectedInstitution,
    setSelectedInstitution,
    handleInstitutionChange,
  };
};
