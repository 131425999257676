import { API_URL } from '@moxie/constants';
import crmAxios from './crm-api-axios';
import { CrmPaginatedResponse } from '@model/api-response.model';
import {
  DealsInterface,
  IAssignee,
  IBranches,
  IDealsPayload
} from '@model/crm/deal.model';
import { IContactCrm } from '@model/index';
import { AxiosResponse } from 'axios';
import { Application } from '@model/application';

interface IDealApplicationParams {
  filter: {
    dealId: string;
    [key: string]: unknown;
  },
  [key: string]: unknown;
}

export const getDeals = async (params?: Record<string, unknown>) => {
  const { data } = await crmAxios.get<CrmPaginatedResponse<DealsInterface>>(
    `${API_URL.CRM_API_DEALS}`,
    {
      params,
    }
  );
  return data;
};

export const getDealDetails = async (dealId: string) => {
  const { data } = await crmAxios.get<AxiosResponse<DealsInterface>>(
    `${API_URL.CRM_API_DEALS}/${dealId}`
  );
  return data;
};

export const updateDeals = async ({
  id,
  data,
}: {
  id: string;
  data: any;
}): Promise<any> => {
  return crmAxios.patch(`${API_URL.CRM_API_DEALS}/${id}`, data);
};

export const getDealBranches = async (params?: Record<string, unknown>) => {
  return (
    await crmAxios.get<CrmPaginatedResponse<IBranches>>(
      `${API_URL.CRM_API_OFFICE}/query`,
      {
        params,
      }
    )
  ).data;
};

export const getDealContacts = async (params?: Record<string, unknown>) => {
  return (
    await crmAxios.get<CrmPaginatedResponse<IContactCrm>>(`${API_URL.CRM_API_CONTACT}`, {
      params,
    })
  ).data;
};

export const getDealUsers = async (params?: Record<string, unknown>) => {
  return (
    await crmAxios.get<CrmPaginatedResponse<IAssignee>>(`${API_URL.CRM_API_USER}`, {
      params,
    })
  ).data;
};

export const getDealAssignees = async () => {
  return crmAxios.get<CrmPaginatedResponse<any>>(`${API_URL.CRM_API_USER}`);
};

export const createDeals = (payload: IDealsPayload[]) =>
  crmAxios.post<{ data: any }>(API_URL.CRM_API_DEALS, payload);

export const getDealApplications = async (params: IDealApplicationParams | Record<string, unknown>) => {
  const { data } = await crmAxios.get<CrmPaginatedResponse<Application>>(
    API_URL.CRM_API_APPLICATION,
    {
      params,
    }
  );
  return data.data;
};

export const fetchApplicationAssigneesInDeal = async (dealId: string) => {
  const { data } = await crmAxios.get<{ data: IAssignee[] }>(`${API_URL.CRM_API_DEALS}/${dealId}/assignees`)
  return data.data;
}
