import { AuthUser, ILogin } from '@shared-components/models';
import { createAction, createSlice } from '@reduxjs/toolkit';
import { CRM_EMAIL_LABEL } from '@moxie/constants';
import { BranchPermission } from '@model/permission.model';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  user: AuthUser | null;
  sessionRefreshModal: boolean;
  error: null;
  authUserPermission: BranchPermission | null;
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  loading: false,
  sessionRefreshModal: false,
  user: null,
  error: null,
  authUserPermission: null,
};

export const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState: initialAuthState,
  reducers: {
    loading: (state, action) => {
      state.loading = action.payload;
    },

    error: (state) => {
      state.isAuthenticated = false;
      state.loading = false;
    },

    setUser: (state, action) => {
      state.isAuthenticated = true;
      localStorage.setItem(CRM_EMAIL_LABEL, action.payload.email);
      state.user = action.payload;
      state.loading = false;
    },

    getAuthUser: (state) => {
      state.loading = true;
    },

    authUser: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.loading = false;
    },

    isAuthenticated: (state) => {
      state.isAuthenticated = true;
    },

    openSessionModal: (state) => {
      state.sessionRefreshModal = true;
    },
    closeSessionModal: (state) => {
      if (state.sessionRefreshModal) window.location.reload();
      state.sessionRefreshModal = false;
    },

    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.loading = false;
      state.error = null;
    },
    fetchAuthPermission(state) {
      state.authUserPermission = null;
    },
    updateAuthPermission(state, action) {
      state.authUserPermission = action.payload
    },
  },
});

const login = createAction<ILogin>(`${AUTH_FEATURE_KEY}/login`);

export const authReducer = authSlice.reducer;

export const authActions = { ...authSlice.actions, login };
