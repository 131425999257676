import {
  institutionActions,
  institutionBranchesActions,
  productTypeActions,
  useAppSelector,
  workflowActions
} from '@crm/core';
import { FormInstance } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useHandleServiceChange = (form: FormInstance<any>) => {
  const [selectedService, setSelectedService] = useState<
    string | undefined | any
  >();

  const dispatch = useDispatch();
  const institutions = useAppSelector(
    (state) => state.institutions.institutions
  );
  const institutionsLoading = useAppSelector(
    (state) => state.institutions.loading
  );
  const productTypes = useAppSelector((state) => state.product_type.allData);
  const productTypesLoading = useAppSelector(
    (state) => state.product_type.loading
  );

  const handleServiceChange = (value: string) => {
    setSelectedService(value);
    form.setFieldsValue({
      institutionId: undefined,
      branches: undefined,
      workflows: undefined,
      productTypeId: undefined,
      productSubTypeId: undefined,
    });
    dispatch(institutionBranchesActions.clearBranchFetch());
    dispatch(workflowActions.clearCRMWorkflowFetch());
  };

  useEffect(() => {
    if (selectedService) {
      dispatch(
        institutionActions.getInstitutionByServiceRequest(selectedService)
      );
      dispatch(
        productTypeActions.getProductTypeByServiceIdRequest(selectedService)
      );
    }
  }, [selectedService]);

  return {
    institutions,
    institutionsLoading,
    productTypes,
    productTypesLoading,
    selectedService,
    setSelectedService,
    handleServiceChange,
  };
};
