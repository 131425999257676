import { API_URL } from '@moxie/constants';
import { ITestTypeForForm } from '@shared-components/models';
import axios from './axios';
import crmAxios from './crm-api-axios';
import { ICrmProduct, ProductView } from 'apps/crm/src/pages/products/product.model';
import { CrmPaginatedResponse } from '@model/api-response.model';

const postCRMProduct = async (data: ICrmProduct): Promise<ICrmProduct> => {
  return (await crmAxios.post(API_URL.CRM_API_PRODUCTS, data))?.data;
};

const updateCRMProduct = async (id: string, data: any): Promise<ICrmProduct> => {
  return crmAxios.put(`${API_URL.CRM_API_PRODUCTS}/${id}`, data);
};

const deleteCRMProduct = async (id: string): Promise<ICrmProduct> => {
  return crmAxios.delete(`${API_URL.CRM_API_PRODUCTS}/${id}`);
};

const getCRMProductsList = async (params: any) => {
  return (await crmAxios.get<CrmPaginatedResponse<ProductView>>(API_URL.CRM_API_PRODUCTS, { params }))?.data.data;
};

const getCRMSingleProduct = async (productId: string) => {
  const response = await crmAxios.get<{ data: ICrmProduct }>(
    `${API_URL.CRM_API_PRODUCTS}/${productId}`
  );
  return response?.data?.data;
};

const getProductInstitutionBranchesData = async (productId: string) => {
  return axios.get(`${API_URL.CRM_PRODUCTS}/${productId}/branches`);
};

const getProductWorkflowsData = async (productId: string) => {
  return axios.get(`${API_URL.CRM_PRODUCTS}/${productId}/workflows`);
};

const postCRMProductLanguageRequirements = async (data: ITestTypeForForm) => {
  return crmAxios.post(`${API_URL.CRM_PRODUCT_LANGUAGE_TEST_SCORE}`, data);
};
const getCRMProductLanguageRequirements = async (id: string) => {
  return crmAxios.get(`${API_URL.CRM_PRODUCT_LANGUAGE_TEST_SCORE}/${id}`);
};

const getAllCRMProductLanguageRequirements = async (id: string) => {
  return (await crmAxios.get(
    `${API_URL.CRM_PRODUCT_LANGUAGE_TEST_SCORE}/`, {
      params: { 'filter.productId': id },
    }
  ))?.data
};

const updateCRMProductLanguageRequirements = (
  id: string,
  data: any
): Promise<string> => {
  return crmAxios.put(
    `${API_URL.CRM_PRODUCT_LANGUAGE_TEST_SCORE}/${id}`,
    data
  );
};

export {
  postCRMProduct,
  updateCRMProduct,
  deleteCRMProduct,
  getCRMSingleProduct,
  getCRMProductsList,
  getProductInstitutionBranchesData,
  getProductWorkflowsData,
  postCRMProductLanguageRequirements,
  getCRMProductLanguageRequirements,
  getAllCRMProductLanguageRequirements,
  updateCRMProductLanguageRequirements
};
