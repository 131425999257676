import { useQuery } from '@tanstack/react-query';
import { errorNotificationHandler } from '@moxie/shared';
import { fetchInterestedServices } from '@crm/libs/services.api/lib/interested-services.api';
import { sortAlphabetically } from '@crm/libs/helpers';

export const useFetchInterestedServices = (includeNotSure = false) => {
  const { data, isLoading } = useQuery({
    queryKey: ['interested-services'],
    queryFn: async () => fetchInterestedServices(),
    enabled: true,
    onError: () =>
      errorNotificationHandler('Error fetching interested services.'),
  });

  const interestedServices = data?.data || [];
  const treeData = interestedServices
    .filter((item) => (includeNotSure ? true : item.name !== 'Not sure'))
    .map((item: any) => {
      const children = []
      if (item.payload.countries.length) {
        const countries = item.payload.countries.sort((a: string, b: string) => sortAlphabetically(a, b));
        children.push({
          title: 'countries',
          key: 'countries',
          children: countries.map((country: any) => ({
            title: country,
            key: country,
          })),
        })
      }

      if (item.payload?.degreeLevels?.length || item.payload.interests?.length) {
        children.push({
          title: 'interests',
          key: 'interests',
          children: item.payload.degreeLevels
            ? item.payload?.degreeLevels?.map((degree: any) => ({
              title: degree,
              key: degree,
            }))
            : item.payload?.interests?.map((interest: any) => ({
              title: interest,
              key: interest,
            })),
        })
      }
      if (item.payload?.type?.length) {
        children.push({
          title: 'type',
          key: 'type',
          children: item.payload?.type?.map((type: string) => ({
            title: type,
            key: type
          }))
        })
      }

      return {
        title: item.name,
        key: item.name,
        id: item.id,
        children,
        level: item.payload?.level
      };
    }).sort((a, b) => a.level - b.level);
  return { interestedServices, treeData, isLoading };
};
