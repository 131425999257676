import {
  applyMiddleware,
  combineReducers,
  createStore
} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { THEME_FEATURE_KEY, themeReducer } from './theme/theme.reducer';
import { authReducer, AUTH_FEATURE_KEY } from './auth/auth.reducer';
import saga from './sagas/saga';
import { serviceReducer, SERVICE_FEATURE_KEY } from './service/service.reducer';
import {
  rolePermissionReducer,
  ROLE_PERMISSION_FEATURE
} from './role_permissions/role-permission.reducer';
import {
  OFFICE_BRANCH_FEATURE_KEY,
  officeBranchReducer
} from './office/office-branch.reducer';
import { userReducer, USER_FEATURE_KEY } from './user/user.reducer';
import {
  officeVisitReducer,
  OFFICE_VISIT_FEATURE_KEY
} from './office-visit/office-visit.reducer';
import {
  workflowTypeReducer,
  WORKFLOW_TYPE_FEATURE_KEY
} from './workflow-type/workflow-type.reducer';
import { INSTITUTION_FEATURE_KEY } from 'apps/admin/src/core/institutions/institution.reducer';
import { institutionReducer } from './institutions/institution.reducer';
import { LEADS_FEATURE_KEY, leadReducer } from './lead/lead.reducer';
import {
  workflowReducer,
  WORKFLOW_FEATURE_KEY
} from './workflow/workflow.reducer';
import { GOOGLE_FEATURE_KEY, googleReducer } from './google/google.reducer';
import { contactReducer, CONTACT_FEATURE_KEY } from './contact/contact.reducer';
import {
  subjectDisciplineReducer,
  SUBJECT_DISCIPLINE_FEATURE_KEY
} from './subject-disciplines/subject-discipline.reducer';
import {
  DOCUMENT_FEATURE_KEY,
  documentReducer
} from './documents/document.reducer';
import {
  BRANCHES_BY_INSTITUTION_FEATURE_KEY,
  branchesByInstitutionReducer
} from './institution-branch/institution-branch.reducer';
import {
  PRODUCT_TYPE_BY_SERVICE_ID_FEATURE_KEY,
  productTypeByServiceIdReducer
} from './product-type/product-type.reducer.';
import {
  PRODUCTS_FEATURE_KEY,
  productReducer
} from './product/product.reducer';

import {
  COUNTRY_BY_SERVICE_FEATURE_KEY,
  countryByServiceReducer
} from './country/country-service.reducer.';
import { SOCKET_FEATURE_KEY, socketReducer } from './socket/socket.reducer';
import { dealReducer, DEAL_FEATURE_KEY } from './deal/deal.reducer';
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const rootReducer = combineReducers({
  [THEME_FEATURE_KEY]: themeReducer,
  [AUTH_FEATURE_KEY]: authReducer,
  [SERVICE_FEATURE_KEY]: serviceReducer,
  [ROLE_PERMISSION_FEATURE]: rolePermissionReducer,
  [OFFICE_BRANCH_FEATURE_KEY]: officeBranchReducer,
  [LEADS_FEATURE_KEY]: leadReducer,
  [USER_FEATURE_KEY]: userReducer,
  [OFFICE_VISIT_FEATURE_KEY]: officeVisitReducer,
  [INSTITUTION_FEATURE_KEY]: institutionReducer,
  [WORKFLOW_TYPE_FEATURE_KEY]: workflowTypeReducer,
  [WORKFLOW_FEATURE_KEY]: workflowReducer,
  [GOOGLE_FEATURE_KEY]: googleReducer,
  [CONTACT_FEATURE_KEY]: contactReducer,
  [SUBJECT_DISCIPLINE_FEATURE_KEY]: subjectDisciplineReducer,
  [DOCUMENT_FEATURE_KEY]: documentReducer,
  [BRANCHES_BY_INSTITUTION_FEATURE_KEY]: branchesByInstitutionReducer,
  [PRODUCT_TYPE_BY_SERVICE_ID_FEATURE_KEY]: productTypeByServiceIdReducer,
  [PRODUCTS_FEATURE_KEY]: productReducer,
  [COUNTRY_BY_SERVICE_FEATURE_KEY]: countryByServiceReducer,
  [SOCKET_FEATURE_KEY]: socketReducer,
  [DEAL_FEATURE_KEY]: dealReducer,
});

const sagaMiddleware = createSagaMiddleware();
const middleWares = [sagaMiddleware];

const configureStore = () => {
  const middlewareEnhancer = applyMiddleware(...middleWares);
  const composedEnhancers = composeWithDevTools(middlewareEnhancer, sentryReduxEnhancer);
  const store = createStore(rootReducer, composedEnhancers);
  sagaMiddleware.run(saga);
  return store;
};

const store = configureStore();

export type AppDispatch = typeof store.dispatch | any;
export type RootState = ReturnType<typeof store.getState>;

export default store;

export const createAction = (type: string | any, ...argNames: any) => (
  ...args: any
) => {
  const action: any = { type };
  argNames.forEach((arg: any, index: any) => {
    action[argNames[index]] = args[index];
  });
  return action;
};
