import { Assignee } from '@model/application';
import { Task, TaskFormFields } from '@model/crm/note.model';
import { capitalizeTrimWord, errorNotificationHandler, popConfirm } from '@moxie/shared';
import Collapse from 'antd/es/collapse';
import { Row } from 'antd/es/grid';
import TimelineItem from 'antd/lib/timeline/TimelineItem';
import React, { useContext, useEffect, useState } from 'react';
import { useTipTapEditor } from '../editor';
import moment from 'moment';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import SaveOutlined from '@ant-design/icons/SaveOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { useUpdateNote } from './hooks';
import useDeleteNote from './hooks/useDeleteNote';
import NoteForm from './note-form';
import { NoteFormContext } from './note-form-provider';
import difference from 'lodash/difference';

interface Props {
  createdBy: Assignee;
  key: string;
  createdAt: string;
  description: string;
  task?: Task;
  noteId: string;
  assignees: string[];
  contactId?: string;
  type: string;
}

const NoteCard: React.FC<Props> = ({ description, createdBy, key, createdAt, noteId, assignees, task, contactId, type }) => {
  const editor = useTipTapEditor(description, type);
  const updateNote = useUpdateNote();
  const deleteNote = useDeleteNote(noteId);
  const { noteMode, setNoteMode, validateForm } = useContext(NoteFormContext);

  const [form] = Form.useForm<TaskFormFields>();
  const [isInitial, setInitial] = useState(false);

  useEffect(() => {
    if (task) {
      setInitial(true)
      const dueDate = moment(task.dueAt);
      form.setFieldsValue({
        dueTime: dueDate,
        dueAt: dueDate,
        dueDateLabel: 'custom date',
        type: task.type,
        createTask: true,
      })
      setInitial(false)
    }
    else {
      setInitial(true);
      const dueDate = moment().add('days', 1);
      form.setFieldsValue({
        dueDateLabel: 'tomorrow',
        type: 'to do',
        createTask: false,
        dueAt: dueDate,
      })
      setInitial(false)

    }
  }, [task, form]);

  const onSubmit = async (values: TaskFormFields) => {
    if (!editor) {
      return;
    }
    await validateForm(editor, form, values, async (content, updatedAssignees) => {

      const removeAssignees = difference(assignees, updatedAssignees)
      setNoteMode('save');
      await updateNote.mutateAsync({
        noteId: noteId,
        assignees: updatedAssignees,
        description: content,
        removeAssignees,
        ...(values.createTask ? {
          task: {
            contactId,
            dueAt: values.dueAt.set({
              hours: values.dueTime.get('hours'),
              minutes: values.dueTime.get('minutes'),
            }).toISOString(),
            type: values?.type,
          },
        } : {}),
      }).catch(() => {
        errorNotificationHandler('Something went wrong! Please try again');
        setNoteMode(undefined)
      });
      setNoteMode(undefined);
    })
  }

  const onDeleteSuccess = () => setNoteMode(undefined);
  const removeNote = () => {
    if (task?.id) {
      return popConfirm(
        'Are you sure?',
        'Associated task will also be deleted',
        () => {
          setNoteMode('delete');
          deleteNote.mutate(true, {
            onSuccess: () => onDeleteSuccess(),
          });
        },
        true
      )
    }
    setNoteMode('delete');
    return deleteNote.mutate(false, {
      onSuccess: () => onDeleteSuccess(),
    })
  }

  if (isInitial) {
    return null;
  }
  return (
    <TimelineItem key={key}>
      <Collapse
        accordion
        expandIconPosition="left"
      >
        <Collapse.Panel
          header={
            type === 'enquiry' ? (
              <div className="enquiry-info">
                <p className="enquiry-author enquiry-activity-title">
                  <span className="activity-title"> Note created</span></p>
                <div className="enquiry-date enquiry-activity-date">
                  <div>{moment(createdAt).format('dddd, MMMM D, YYYY')}</div>
                  by {capitalizeTrimWord(`${createdBy.firstName} ${createdBy.lastName}`)}
                </div>
              </div >
            ) :
              (
                <Row style={{ 'display': 'inline-flex', width: '96%' }} justify="space-between">
                  <span>
                    Note created by {capitalizeTrimWord(`${createdBy.firstName} ${createdBy.lastName}`)}
                  </span>
                  <span className="deal-date">
                    {moment(createdAt).format('dddd, MMMM D, YYYY')}
                  </span>
                </Row>
              )
          }
          key={key}>
          <Form form={form} onFinish={onSubmit}>
            <NoteForm
              form={form}
              editor={editor}
              task={task}
              actions={
                <>
                  <Button type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={noteMode !== undefined} loading={noteMode === 'save'}>
                    Save
                  </Button>

                  <Button danger htmlType="button" icon={<DeleteOutlined />} onClick={removeNote} disabled={noteMode !== undefined} loading={noteMode === 'delete'}>
                    Delete
                  </Button>
                </>
              }
            />
          </Form>
        </Collapse.Panel>
      </Collapse>
    </TimelineItem>
  )
}

export default NoteCard;
