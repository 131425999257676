import { Collapse } from 'antd';
import moment from 'moment';
import React from 'react';


interface Props {
  name: string;
  createdAt: string;
  data: {
    currentStatus: string;
    previousStatus: string;
  };
}

const { Panel } = Collapse;


export const EnquiryStatusActivity: React.FC<Props> = ({
  name,
  createdAt,
  data,
}) => {
  const { currentStatus, previousStatus } = data;

  return (
    <>

      <Panel
        style={{
          paddingLeft: 20, padding: 15, background: 'none',
        }}
        key={1}
        header={(
          <div className="enquiry-info">
            <p className="enquiry-author">
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Status </span>changed from <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{previousStatus}</span> to <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{currentStatus}</span></p>
            <div className="enquiry-date">
              <div>{moment(createdAt).format('dddd, MMMM D, YYYY')}</div>
              <div>by {name}</div>
            </div>
          </div>
        )}>
      </Panel >
    </>
  );
};
