import { IAction, PaginationMeta } from '@shared-components/models';
import * as actionTypes from './product.constant';
import { ICrmProduct, ProductView } from '../../pages/products/product.model';

export interface IProductState {
  products: ProductView[];
  product: Partial<ICrmProduct>;
  error: boolean | null;
  loading: boolean;
  meta: PaginationMeta,
}

const initialProductState: IProductState = {
  products: [],
  product: {},
  error: null,
  loading: false,
  meta: {
    itemsPerPage: 10,
    totalItems: 0,
    currentPage: 1,
    totalPages: 0,
    sortBy: [],
    search: undefined,
    searchBy: undefined,
  },
};

export const PRODUCTS_FEATURE_KEY = 'products';

export const productReducer = (
  state = initialProductState,
  action: IAction<any>
): IProductState => {
  switch (action.type) {
    case actionTypes.ADD_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        product: action.payload,
      };
    case actionTypes.ADD_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        products: action.payload.data,
        meta: action.payload?.meta,
      };
    case actionTypes.GET_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
