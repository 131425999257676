import { deleteNote } from "@crm/services.api";
import { successNotificationHandler } from "@moxie/shared";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useDeleteNote = (noteId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (deleteTask: boolean) => deleteNote(noteId, deleteTask),
    onSuccess() {
      successNotificationHandler('Note delete successfully.')
      queryClient.invalidateQueries({
        queryKey: ['notes'],
      })
    },
  })
}

export default useDeleteNote;
