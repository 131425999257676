import { API_URL } from '@moxie/constants';
import {
  IAuthResponse,
  IForgetPassword,
  IAuth,
  IForgetPasswordResponse,
  ILogin,
  ILoginResponse,
  AuthUser
} from '@shared-components/models';
import axios from './lib/axios';
import { crmAxios } from './lib';

interface VerifyAccount {
  token: string;
  email: string;
  password: string;
}


const login = (body: ILogin): Promise<ILoginResponse> => {
  return crmAxios.post(`${API_URL.LOGIN}`, {
    ...body,
    domain: window.location.hostname,
  });
};

const findLoggedInUser = () => {
  return crmAxios.get<{ data: AuthUser }>(API_URL.GET_CURRENT_USER)
}

const auth = (body: IAuth): Promise<IAuthResponse> => {
  return axios.post(API_URL.RESET_PASSWORD, body);
};

const logoutUser = () => {
  // TODO: Perform logout action here
  return crmAxios.post(API_URL.LOGOUT);
};
const forgetCrmPassword = (
  body: IForgetPassword
): Promise<IForgetPasswordResponse> => {
  return crmAxios.post(API_URL.CRM_FORGET_PASSWORD, body);
};

const changeCRMAccountPasswordAPI = (body: { oldPassword: string; newPassword: string }): Promise<any> => {
  return crmAxios.post(API_URL.CHANGE_CRM_ACCOUNT_PASSWORD, body);
};

// auth/resetpassword
const CRMResetPassword = (data: IAuth) => {
  return crmAxios.post(API_URL.RESET_PASSWORD, data);
};

const verifyCrmAccount = (body: VerifyAccount): Promise<IAuthResponse> => {
  return crmAxios.post(API_URL.EMAIL_VERIFICATION, body);
};

const resendVerifyCrmAccountLink = (
  body: IForgetPassword
): Promise<IAuthResponse> => {
  return crmAxios.post(API_URL.RESEND_EMAIL_VERIFICATION, body);
};

export {
  login,
  logoutUser,
  changeCRMAccountPasswordAPI,
  forgetCrmPassword,
  auth,
  verifyCrmAccount,
  resendVerifyCrmAccountLink,
  CRMResetPassword,
  findLoggedInUser
};
