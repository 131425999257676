import { getPaginatedTasks } from "@crm/libs/services.api/lib/tasks.api";
import { PaginationMeta } from "@model/api-response.model";
import { Task, TaskStatusFilter } from "@model/crm/note.model";
import { useInfiniteQuery } from "@tanstack/react-query";


interface Props {
  assignees?: string[];
  createdById?: string;
  startDate?: string;
  endDate?: string;
  status: TaskStatusFilter;
}
const usePaginateTasks = ({ assignees, createdById, startDate, endDate, status }: Props) => {
  const { data, fetchNextPage, isLoading, hasNextPage } = useInfiniteQuery<{ data: Task[], meta: PaginationMeta }>({
    queryKey: ['tasks', assignees, createdById, startDate, endDate, status],
    cacheTime: 100,
    queryFn: async (args) => {
      let dueDateFilter = undefined;
      let sortBy = 'dueAt:ASC';

      if (startDate) {
        dueDateFilter = '$gte:' + startDate;
      }
      if (endDate) {
        dueDateFilter = '$lte:' + endDate;
      }
      if (startDate && endDate) {
        dueDateFilter = '$btw:' + startDate + ',' + endDate;
      }
      if (startDate && endDate && startDate === endDate) {
        dueDateFilter = '$eq:' + startDate;
      }


      if (status === 'done') {
        sortBy = 'completedAt:DESC'
      }
      const params = {
        page: args.pageParam ?? 1,
        limit: 10,
        'filter.dueDate': dueDateFilter,
        'filter.status': status !== 'all' ? status : undefined,
        sortBy,
      };

      return (await getPaginatedTasks(params)).data;
    },
    getNextPageParam: (lastPage) => lastPage.meta.currentPage === lastPage.meta.totalPages ? false : lastPage.meta.currentPage + 1,

  });


  return {
    tasks: data?.pages.map(paginatedNotes => paginatedNotes.data).flat() ?? [],
    next: fetchNextPage,
    isLoading,
    hasMore: hasNextPage ?? false,
  }
}

export default usePaginateTasks;
