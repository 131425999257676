import {
  CaretRightOutlined,
  CheckCircleOutlined,
  ProfileOutlined,
  UserOutlined
} from '@ant-design/icons';
import { officeVisitActions, serviceActions, useAppSelector } from '@crm/core';
import { useOfficeVisitUpdate } from '@crm/libs/hooks/office-visits/useOfficeVisitUpdate';
import { queryAssignees } from '@crm/services.api';
import { ArchiveButton, ViewDetailsButton } from '@crm/src/shared/buttons';
import { SESSION_NOTE_REQUIRED, UNASSIGNED_LABEL, URL_DETAIL_LINK } from '@moxie/constants';
import { errorNotificationHandler } from '@moxie/shared';
import { IContactCrm, IOfficeVisitCrm } from '@shared-components/models';
import { useQueryClient } from '@tanstack/react-query';
import {
  Avatar,
  Button,
  Col,
  Form,
  Row,
  Select,
  Space,
  Tooltip,
  Typography
} from 'antd';
import { Assignee } from 'libs/shared/src/crm-modules/user/user.model';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { UnassignedCard, UserNameCard } from '../../shared';
import SearchBox from '../../shared/search-box';
import { IUserData } from '../../user/user.model';
import { removeDefTags } from './removeTagFn';
import { UserCard } from './user-card';


export const Countdown: React.FC<any> = ({ data }: { data: any }) => {
  const initialDifference = moment.duration(moment().diff(moment(data)));
  const [difference, setDifference] = useState(initialDifference);

  const count = () =>
    setInterval(() => {
      setDifference(moment.duration(moment().diff(moment(data))));
    }, 1000);

  useState(() => {
    count();

    return () => {
      clearInterval(count());
    };
  });

  return (
    <>
      <Typography.Text>
        {difference.get('h') !== 0 && difference.get('h') + 'h'}{' '}
        {difference.get('m')}m
      </Typography.Text>
    </>
  );
};

export const OfficeVisitAction: React.FC<{ data: IOfficeVisitCrm }> = ({ data }: { data: IOfficeVisitCrm }) => {
  const { user, currentUserBranchId } = useAppSelector((state) => ({
    user: state.auth.user,
    currentUserBranchId: state.auth.user?.branch?.id,
  }));
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const socket = useAppSelector((state) => state.socket.wss);
  const branches = useAppSelector((state) => state.office_branches.allData);

  const triggerRefresh = () => {
    dispatch(officeVisitActions.officeVisitTableRefresh());
  };

  const handleView = () => {
    dispatch(officeVisitActions.getOfficeVisitRequest(data?.id));
    dispatch(officeVisitActions.openOfficeVisitDetailsDrawer());
    queryClient.invalidateQueries({
      queryKey: ['contact-enquiry', data?.contact?.id]
    });
    queryClient.invalidateQueries({
      queryKey: ['deals-listing', { 'filter.contactId': data?.contact?.id }],
    });
  };

  const handleAttendSession = () => {
    const updateData: Partial<IOfficeVisitCrm> = {
      status: 'Attending',
      sessionStart: moment(),
      assigneeId: user?.id,
      sessionNotes: removeDefTags(data?.note?.description ?? data.sessionNotes),
    };

    dispatch(
      officeVisitActions.updateOfficeVisitRequest(data.id, updateData,
        () => {
          if (user) {
            // socket?.emit('server::office-visit-updated', {
            //   assignee: data?.assignee?.id as string,
            //   branchId: branches.find((branch) => branch.id === data.branchId)
            //     ?.id as string,
            //   contactId: data?.contact?.id,
            //   userId: user?.id as string,
            //   type: 'attended',
            //   companyId: user.companyId,
            //   activitiesTypeId: data?.contact?.id
            // });
            socket?.emit('server::branches-updated', {
              addedBranches: branches
                .filter((branch) => branch.id === data.branchId)
                ?.map(branch => branch.id) as string[],
              removedBranches: [],
              userId: user?.id as string,
              contactId: data?.contact?.id,
              companyId: user?.companyId as string,
              activitiesTypeId: data?.contact?.id
            });
          }
          triggerRefresh();
        }
      )
    );
  };

  const handleEndSession = (data: IOfficeVisitCrm) => {
    const sessionNote = removeDefTags(data?.note?.description ?? data.sessionNotes);
    if (sessionNote.length === 0) {
      handleView();
      errorNotificationHandler(SESSION_NOTE_REQUIRED);
    } else {
      const updateData: Partial<IOfficeVisitCrm> = {
        status: 'Completed',
        sessionEnd: moment(),
      };
      dispatch(
        officeVisitActions.updateOfficeVisitRequest(
          data.id,
          updateData,
          triggerRefresh
        )
      );
    }
  };

  const checkCanAttendEndSession = (): boolean => {
    if (currentUserBranchId === data?.branchId) {
      return !(user?.id === data?.assignee?.id);
    }
    return true;
  };

  const checkAssigneeLogged = (): boolean => {
    return !(user?.id === data?.assignee?.id);
  };

  return (
    <div className="display-flex">
      {data?.status === 'Waiting' && (
        <Link to={`/office-visit/attending`}>
          <Button
            disabled={checkCanAttendEndSession()}
            onClick={handleAttendSession}
            className="green-btn margin-right-1"
            data-testid="crm-officevisitattendbutton"
          >
            <CaretRightOutlined />
            Attend
          </Button>
        </Link>
      )}
      {data?.status === 'Attending' && (
        <Link to={removeDefTags(data?.sessionNotes).length > 0 ? `/office-visit/completed` : `/office-visit/attending`}>
          <Button
            disabled={checkAssigneeLogged()}
            onClick={() => handleEndSession(data)}
            className="emerald-green-btn margin-right-1"
            data-testid="crm-officevisitendbutton"
          >
            <CheckCircleOutlined />
            End Session
          </Button>
        </Link>
      )}
      <Button onClick={handleView} data-testid="crm-officevisitviewdetails">
        <ProfileOutlined />
        View Details
      </Button>
    </div>
  );
};

export const PendingOfficeVisitAction: React.FC<{ data: IOfficeVisitCrm }> = ({ data }: { data: IOfficeVisitCrm }) => {
  const officeVisitUpdate = useOfficeVisitUpdate(data?.id);
  const triggerRefresh = () => {
    dispatch(officeVisitActions.officeVisitTableRefresh());
  };

  const handleArchive = () => {
    officeVisitUpdate.mutate({ status: "Unattended" }, {
      onSettled: () => {
        triggerRefresh();
      }
    });

  }

  const dispatch = useDispatch();

  const handleView = () => {
    dispatch(officeVisitActions.openOfficeEnquiryDrawer(data?.enquiry?.id as string));
  };

  const handleApprove = () => {
    dispatch(officeVisitActions.getOfficeVisitRequest(data?.id));
    if (data?.enquiry?.type !== "New") {
      dispatch(officeVisitActions.getPrevOfficeVisitRequest({ contactId: data?.contact?.id, limit: 3, officeVisitId: data?.id }));
    }
    dispatch(serviceActions.getServicesRequest());
    dispatch(officeVisitActions.openApproveModal())
  }

  return (
    <Row>
      <Space>
        <Button type='primary' onClick={handleApprove}> <CheckCircleOutlined /> Approve</Button>
        <ArchiveButton onClick={handleArchive} />
        <ViewDetailsButton onClick={handleView} />
      </Space>
    </Row>
  )
}

const OfficeVisitAssigneeCard = ({ data, officeVisitId }: { data: IUserData; officeVisitId: string; }) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { currentUserBranchId } = useAppSelector((state) => ({
    currentUserBranchId: state.auth.user?.branchId,
  }));
  // const socket = useAppSelector((state) => state.socket.wss);
  // const loggedUser = useAppSelector((state) => state.auth.user);
  // const branches = useAppSelector((state) => state.office_branches.allData);

  const editAssigneeClick = () => {
    setIsEdit(true);
  };

  const handleEditCancel = () => {
    setIsEdit(false);
  };

  const triggerRefresh = () => {
    dispatch(officeVisitActions.officeVisitTableRefresh());
  };

  const updateAssignee = (assigneeId: string) => {
    // const removedAssignee = data?.assignee?.id ?? null;
    if (assigneeId === '' || !assigneeId) {
      dispatch(
        officeVisitActions.updateOfficeVisitRequest(
          officeVisitId,
          { assigneeId: null },
          () => {
            triggerRefresh();
            // socket?.emit('server::office-visit-updated', {
            //   assignee: assigneeId,
            //   removed_assignee: removedAssignee,
            //   branch: branches.find((branch) => branch.id === data.branch_id)
            //     ?.name as string,
            //   contact_id: data.contact.id,
            //   crm_user_id: user?.id as string,
            //   type: 'changed',
            // });
          }
        )
      );
    } else {
      dispatch(
        officeVisitActions.updateOfficeVisitRequest(
          officeVisitId,
          { assigneeId },
          () => {
            triggerRefresh();
            // socket?.emit('server::office-visit-updated', {
            //   assignee: assigneeId,
            //   removed_assignee: removedAssignee,
            //   branch: branches.find((branch) => branch.id === data.branch?.id)
            //     ?.name as string,
            //   contact_id: data.contact.id,
            //   crm_user_id: user?.id as string,
            //   type: 'changed',
            // });
          }
        )
      );
    }
    handleEditCancel();
  };

  return (
    <>
      {data?.id && !isEdit ? (
        <div className="cursor-pointer" onClick={editAssigneeClick}>
          <UserNameCard
            index={data?.id}
            firstName={data?.firstName}
            lastName={data?.lastName}
            email={data?.email}
            id={data?.id}
            actionBar={false}
          />
        </div>
      ) : isEdit ? (
        <Row
          className="lead-table__name user-name-card"
          style={{ width: '8rem' }}
        >
          <Col className="align-items-center user-name-card__avatar">
            <Tooltip title="unassigned">
              <Avatar icon={<UserOutlined />} alt="unassigned" />
            </Tooltip>
          </Col>
          <Col>
            <Form layout="inline">
              <Space direction="horizontal">
                <Form.Item
                  name="assigneeId"
                  className="inline_input_width"
                >
                  <SearchBox
                    hideDefaultOptionView
                    fetchQuery={queryAssignees}
                    filter={{
                      branches: [currentUserBranchId]
                    }}
                    onChange={updateAssignee}
                    optionLabelProp='label'
                    defaultOpen
                    onDropdownVisibleChange={handleEditCancel}
                    emptyOption={<Select.Option value="" label={UNASSIGNED_LABEL}> <UnassignedCard /> </Select.Option>}
                  >
                    {
                      (option: Assignee) => {
                        const { id, firstName, lastName, email } = option;
                        return (
                          <>
                            <Select.Option
                              value={id}
                              label={`${firstName} ${lastName}`}
                            >
                              <UserCard
                                firstName={firstName}
                                lastName={lastName}
                                email={email}
                                index={id}
                              />
                            </Select.Option>
                          </>
                        )
                      }
                    }
                  </SearchBox>
                </Form.Item>
              </Space>
            </Form>
          </Col>
        </Row>
      ) : (
        <div className="cursor-pointer" onClick={editAssigneeClick}>
          <UnassignedCard />
        </div>
      )}
    </>
  );
};

const pendingColumns = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: (data: string): ReactElement => {
      return (
        <>
          <Typography.Text strong>
            {moment(data).format('Do MMM, YYYY')}
          </Typography.Text>
          <div className="justify-content-center">
            <Typography.Text strong className="text-primary-color">
              {moment(data).format('dddd')}
            </Typography.Text>
          </div>
        </>
      );
    },
  },
  {
    title: 'Arrival Time',
    dataIndex: 'createdAt',
    width: 150,
    render: (createdAt: string): ReactElement => {
      return (
        <Typography.Text>{moment(createdAt).format('h:mm A')}</Typography.Text>
      );
    },
  },
  {
    title: 'Wait Time',
    dataIndex: 'createdAt',
    width: 150,
    render: (data: string): ReactElement => {
      return <Countdown data={data} />;
    },
  },
  {
    title: 'Contact Name',
    dataIndex: 'contact',
    render: (contact: IContactCrm): ReactElement => {
      return (
        <UserNameCard
          firstName={contact?.firstName}
          lastName={contact?.lastName}
          email={contact?.email}
          link={`${URL_DETAIL_LINK}${contact?.id}/activities`}
        />
      );
    },
  },
  {
    title: ' Contact Type',
    dataIndex: 'enquiry',
    width: 150,
    render: (enquiry: { type: string }): ReactElement => {
      return <Typography.Text>{enquiry?.type}</Typography.Text>;
    },
  },
  {
    title: 'Visit Purpose',
    dataIndex: 'visitPurpose',
    width: 150,
    render: (data: { visitPurpose?: string }): ReactElement => {
      return <Typography.Text>{data?.visitPurpose ?? '-'}</Typography.Text>;
    },
  },
  {
    title: 'Actions',
    width: 150,
    render: (row: IOfficeVisitCrm): ReactElement => <PendingOfficeVisitAction data={row} />,
  },
];


const waitingColumns = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: (data: string): ReactElement => {
      return (
        <>
          <Typography.Text strong>
            {moment(data).format('Do MMM, YYYY')}
          </Typography.Text>
          <div className="justify-content-center">
            <Typography.Text strong className="text-primary-color">
              {moment(data).format('dddd')}
            </Typography.Text>
          </div>
        </>
      );
    },
  },
  {
    title: 'Arrival Time',
    dataIndex: 'createdAt',
    render: (createdAt: string): ReactElement => {
      return (
        <Typography.Text>{moment(createdAt).format('h:mm A')}</Typography.Text>
      );
    },
  },
  {
    title: 'Wait Time',
    dataIndex: 'createdAt',
    render: (data: string): ReactElement => {
      return <Countdown data={data} />;
    },
  },
  {
    title: 'Contact Name',
    dataIndex: 'contact',
    render: (contact: IContactCrm): ReactElement => {
      return (
        <UserNameCard
          firstName={contact?.firstName}
          lastName={contact?.lastName}
          email={contact?.email}
          link={`${URL_DETAIL_LINK}${contact?.id}/activities`}
        />
      );
    },
  },
  {
    title: 'Contact Type',
    dataIndex: 'contact',
    render: (contact: any): ReactElement => {
      return <Typography.Text>{contact?.status}</Typography.Text>;
    },
  },
  {
    title: 'Assignee',
    dataIndex: 'assignee',
    render: (assigneeData: IUserData, { id }: IOfficeVisitCrm): ReactElement => {
      return <OfficeVisitAssigneeCard data={assigneeData} officeVisitId={id} />
    }
  },
  {
    title: 'Visit Purpose',
    dataIndex: 'visitPurpose',
    render: (data: { visitPurpose?: string }): ReactElement => {
      return <Typography.Text>{data?.visitPurpose ?? '-'}</Typography.Text>;
    },
  },
  {
    title: 'Actions',
    render: (row: any): ReactElement => <OfficeVisitAction data={row} />,
  },
];

const attendingColumns = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: (data: string): ReactElement => {
      return (
        <>
          <Typography.Text strong>
            {moment(data).format('Do MMM, YYYY')}
          </Typography.Text>
          <div className="justify-content-center">
            <Typography.Text strong className="text-primary-color">
              {moment(data).format('dddd')}
            </Typography.Text>
          </div>
        </>
      );
    },
  },
  {
    title: 'Session Start Time',
    dataIndex: 'sessionStart',
    render: (sessionStart: string): ReactElement => (
      <Typography.Text>
        {moment(sessionStart).format('h:mm A')}
      </Typography.Text>
    ),
  },
  {
    title: 'Session Run Time',
    dataIndex: 'sessionStart',
    render: (data: any): ReactElement => {
      return <Countdown data={data} />;
    },
  },
  {
    title: 'Contact Name',
    dataIndex: 'contact',
    render: (contact: IContactCrm): ReactElement => {
      return (
        <UserNameCard
          firstName={contact?.firstName}
          lastName={contact?.lastName}
          email={contact?.email}
          link={`${URL_DETAIL_LINK}${contact?.id}/activities`}
        />
      );
    },
  },
  {
    title: 'Contact Type',
    dataIndex: 'contact',
    render: (contact: any): ReactElement => {
      return <Typography.Text>{contact?.status}</Typography.Text>;
    },
  },
  {
    title: 'Assignee',
    dataIndex: 'assignee',
    render: (data: IUserData): ReactElement => (
      <UserCard
        firstName={data?.firstName}
        lastName={data?.lastName}
        email={data?.email}
        showEmailAsLink={true}
      />
    ),
  },
  {
    title: 'Visit Purpose',
    dataIndex: 'visitPurpose',
    render: (data: { visitPurpose?: string }): ReactElement => {
      return <Typography.Text>{data?.visitPurpose ?? '-'}</Typography.Text>;
    },
  },
  {
    title: 'Actions',
    render: (row: any): ReactElement => {
      return <OfficeVisitAction data={row} />
    },
  },
];

const completedColumns = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: (data: string): ReactElement => {
      return (
        <>
          <Typography.Text strong>
            {moment(data).format('Do MMM, YYYY')}
          </Typography.Text>
          <div className="justify-content-center">
            <Typography.Text strong className="text-primary-color">
              {moment(data).format('dddd')}
            </Typography.Text>
          </div>
        </>
      );
    },
  },
  {
    title: 'Session Start Time',
    dataIndex: 'sessionStart',
    render: (sessionStart: string): ReactElement => {
      return (
        <Typography.Text>
          {moment(sessionStart).format('h:mm A')}
        </Typography.Text>
      );
    },
  },
  {
    title: 'Session End Time',
    dataIndex: 'sessionEnd',
    render: (sessionEnd: string): ReactElement => {
      return (
        <Typography.Text>
          {moment(sessionEnd).format('h:mm:ss A')}
        </Typography.Text>
      );
    },
  },
  {
    title: 'Duration',
    dataIndex: 'id',
    render: (officeVisitId: string, data: any): ReactElement => {
      const { sessionStart, sessionEnd } = data;
      const difference = moment.duration(
        moment(sessionEnd).diff(moment(sessionStart))
      );
      return (
        <Typography.Text>
          {difference.get('h') !== 0 && difference.get('h') + 'h'}{' '}
          {difference.get('m')}m
        </Typography.Text>
      );
    },
  },
  {
    title: 'Contact Name',
    dataIndex: 'contact',
    render: (contact: IContactCrm): ReactElement => {
      return (
        <UserNameCard
          firstName={contact?.firstName}
          lastName={contact?.lastName}
          email={contact?.email}
          link={`${URL_DETAIL_LINK}${contact?.id}/activities`}
        />
      );
    },
  },
  {
    title: 'Contact Type',
    dataIndex: 'contact',
    render: (contact: any): ReactElement => {
      return <Typography.Text>{contact?.status}</Typography.Text>;
    },
  },
  {
    title: 'Assignee',
    dataIndex: 'assignee',
    render: (data: IUserData): ReactElement => (
      <UserCard
        firstName={data?.firstName}
        lastName={data?.lastName}
        email={data?.email}
        showEmailAsLink={true}
      />
    ),
  },
  {
    title: 'Visit Purpose',
    dataIndex: 'visitPurpose',
    render: (data: { visitPurpose?: string }): ReactElement => {
      return <Typography.Text>{data?.visitPurpose ?? '-'}</Typography.Text>;
    },
  },
  {
    title: 'Actions',
    render: (row: any): ReactElement => <OfficeVisitAction data={row} />,
  },
];

const archivedColumns = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: (data: any): ReactElement => {
      return (
        <>
          <Typography.Text strong>
            {moment(data).format('Do MMM, YYYY')}
          </Typography.Text>
          <div className="justify-content-center">
            <Typography.Text strong className="text-primary-color">
              {moment(data).format('dddd')}
            </Typography.Text>
          </div>
        </>
      );
    },
  },
  {
    title: 'Contact Name',
    dataIndex: 'contact',
    render: (contact: any): ReactElement => {
      return (
        <UserNameCard
          firstName={contact?.firstName}
          lastName={contact?.lastName}
          email={contact?.email}
          link={`${URL_DETAIL_LINK}${contact?.id}/activities`}
        />
      );
    },
  },
  {
    title: 'Contact Type',
    dataIndex: 'contact',
    render: (contact: any): ReactElement => {
      return <Typography.Text>{contact?.status}</Typography.Text>;
    },
  },
  {
    title: 'Assignee',
    dataIndex: 'assignee',
    render: (data: IUserData): ReactElement => (
      data ?
        <UserCard
          firstName={data?.firstName}
          lastName={data?.lastName}
          email={data?.email}
          showEmailAsLink={true}
        />
        : <UnassignedCard />
    ),
  },
  {
    title: 'Visit Purpose',
    dataIndex: 'visitPurpose',
    render: (data: any): ReactElement => {
      return <Typography.Text>{data?.visitPurpose ?? '-'}</Typography.Text>;
    },
  },
];

export { pendingColumns, waitingColumns, attendingColumns, completedColumns, archivedColumns };
