import React from "react";
import { Divider, Empty, Spin, Timeline } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { TEXT } from "@moxie/constants";
import { EnquiryActivity } from "@model/contact-form";
import { EnquiryActivityPanels } from "./enquiry-activities/EnquiryActivity";

interface EnquiryViewFormProp {
  activities: EnquiryActivity[];
  isLoading: boolean;
  hasMore: boolean;
  next: () => void;
}

const groupActivitiesByMonth = (activities: EnquiryActivity[]) => {
  return activities.reduce((acc: Record<string, EnquiryActivity[]>, activity: EnquiryActivity) => {
    const month = new Date(activity.createdAt).toLocaleString('default', { month: 'long' });
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push(activity);
    return acc;
  }, {});
};

const EnquiryActivityView = ({ activities, isLoading, hasMore, next }: EnquiryViewFormProp) => {
  const groupedActivities = groupActivitiesByMonth(activities);
  return (
    <>
      <div id="scrollableDiv">
        < InfiniteScroll
          height={650}
          hasMore={hasMore}
          next={() => next()}
          dataLength={activities?.length}
          loader={isLoading ? <Spin /> : null}
          scrollableTarget="scrollableDiv"
          endMessage={
            < Divider plain > You are all caught up</Divider >

          }
        >
          {!activities || activities.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={TEXT.NO_DATA}
            />
          ) : (
            <>
              <Timeline className="margin-top-1">
                {Object.entries(groupedActivities).map(([month, activities]) => (
                  <React.Fragment key={month}>
                    <div className="deal-date padding-bottom-2">
                      {month}
                    </div>
                    {activities.map((item) => (
                      <Timeline.Item key={item.id} >
                        <EnquiryActivityPanels
                          key={item.id}
                          activitiesAction={item.activitiesAction}
                          assignedUser={item.user}
                          createdAt={item.createdAt}
                          data={item.data}
                        />
                      </Timeline.Item>
                    ))}
                  </React.Fragment>
                ))}
              </Timeline>
            </>
          )}
        </InfiniteScroll >
      </div>
    </>
  )
}

export { EnquiryActivityView };
