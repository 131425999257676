import { useMutation, useQueryClient } from "@tanstack/react-query"
import { updateNote } from '@crm/services.api';
import { UpdateNotePayload } from "@model/crm/note.model";
import { successNotificationHandler } from "@moxie/shared";

const useUpdateNote = () => {

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ noteId, ...body }: UpdateNotePayload) => updateNote(noteId, body),
    onSuccess() {
      successNotificationHandler('Note updated successfully');
      queryClient.invalidateQueries({
        queryKey: ['notes'],
      })
    },
  })
}

export default useUpdateNote;
