import { Collapse } from 'antd';
import moment from 'moment';
import React from 'react';
import { EnquiryInterestedServicesActivity } from './EnquiryInterestedServicesActivity';
import { isEqual } from 'lodash';

const { Panel } = Collapse;

type Props = {
  name: string,
  createdAt: string,
  data: {
    currentData: any,
    previousData: any
  },
}

export const EnquiryEditActivity: React.FC<Props> = ({
  name,
  createdAt,
  data,
}) => {

  const { personalInformationChanged, addressInformationChanged, commentChanged, interestedServicesChanged, disciplineChanged, hasIntakeChanged } = hasChanges(data.currentData, data.previousData);
  if (!personalInformationChanged && !addressInformationChanged && !commentChanged && !interestedServicesChanged && !disciplineChanged && !hasIntakeChanged) {
    return null;
  }

  return (
    <>
      {personalInformationChanged && (
        <Panel
          style={{
            paddingLeft: 20, padding: 15, background: 'none',
          }}
          key="personalInformation"
          header={(
            <div className="enquiry-info">
              <p className="enquiry-author">
                <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Personal Information </span>
                updated
              </p>
              <div className="enquiry-date">
                <div>{moment(createdAt).format('dddd, MMMM D, YYYY')}</div>
                <div>by {name}</div>
              </div>
            </div>
          )}>
        </Panel>
      )}
      {addressInformationChanged && (
        <Panel
          style={{
            paddingLeft: 20, padding: 15, background: 'none',
          }}
          key="addressInformation"
          header={(
            <div className="enquiry-info">
              <p className="enquiry-author">
                <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Address Information </span>
                updated
              </p>
              <div className="enquiry-date">
                <div>{moment(createdAt).format('dddd, MMMM D, YYYY')}</div>
                <div>by {name}</div>
              </div>
            </div>
          )}>
        </Panel>
      )}
      {interestedServicesChanged && (
        <>
          <EnquiryInterestedServicesActivity
            name={name}
            createdAt={createdAt}
            data={{
              previousInterestedServices: data.previousData.interestedService,
              interestedServices: data.currentData.interestedService,
            }}
          />
        </>
      )}
      {commentChanged && (
        <>
          <Panel
            style={{
              paddingLeft: 20, padding: 15, background: 'none',
            }}
            key="personalInformation"
            header={(
              <div className="enquiry-info">
                <p className="enquiry-author">
                  <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Comment </span>
                  updated
                </p>
                <div className="enquiry-date">
                  <div>{moment(createdAt).format('dddd, MMMM D, YYYY')}</div>
                  <div>by {name}</div>
                </div>
              </div>
            )}>
          </Panel>
        </>
      )}
      {disciplineChanged && (
        <Panel
          style={{
            paddingLeft: 20, padding: 15, background: 'none',
          }}
          key={1}
          header={(
            <div className="enquiry-info">
              <p className="enquiry-author">
                <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Discipline </span>updated
              </p>
              <div className="enquiry-date">
                <div>{moment(createdAt).format('dddd, MMMM D, YYYY')}</div>
                <div>by {name}</div>
              </div>
            </div>
          )}>
        </Panel >
      )}

      {hasIntakeChanged && (
        <Panel
          className="enquiry-activities-panel"
          key={1}
          header={(
            <div className="enquiry-info">
              <p className="enquiry-author">
                <span className="enquiry-author__text ">Intake </span>updated
              </p>
              <div className="enquiry-date">
                <div>{moment(createdAt).format('dddd, MMMM D, YYYY')}</div>
                <div>by {name}</div>
              </div>
            </div>
          )}>
        </Panel >
      )}
    </>
  );
}

function hasChanges(currentDetails: any, previousDetails: any) {
  const { interestedService: interestedServiceCurrent } = currentDetails;
  const { interestedService: interestedServicePrev } = previousDetails;
  let hasIntakeChanged = false;

  const { intake: intakePrev, ...restInterestedServicePrev } = interestedServicePrev;
  const { intake: intakeCurrent, ...restInterestedServiceCurrent } = interestedServiceCurrent;

  const personalInformationChanged = !isEqual(currentDetails.personalInformation, previousDetails.personalInformation);
  const addressInformationChanged = !isEqual(currentDetails.addressInformation, previousDetails.addressInformation);
  const commentChanged = currentDetails.comment !== previousDetails.comment;
  const interestedServicesChanged = !isEqual(restInterestedServiceCurrent, restInterestedServicePrev);
  const disciplineChanged = !isEqual(currentDetails.type, previousDetails.type);

  if (JSON.stringify(intakePrev) !== JSON.stringify(intakeCurrent)) {
    hasIntakeChanged = true;
  }

  return {
    personalInformationChanged,
    addressInformationChanged,
    commentChanged,
    interestedServicesChanged,
    disciplineChanged,
    hasIntakeChanged
  };
}
