import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/***
  * Similar to https://reactrouter.com/6.28.0/hooks/use-search-params
  * Implementation for strings and arrays as parameter is not implemented in this hook
  * @example
*   For "abc.com?uerId=1"

  const [params,setSearchParams] = useSearchParams();

  params.get('userId'); // gives 1
  params.get('queryNotInUrl') // gives null

  params.set('userId','5')
  setSearchParams(params) // url becomes:  abc.com?uerId=5

  setSearchParams({userId:6,role:"admin"}) // url becomes:  abc.com?uerId=7&role=admin

  setSearchParams() // url becomes abc.com
 * */
export const useSearchParams: () => [
  URLSearchParams,
  (query?: Record<string, string> | URLSearchParams)
    => void
] = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const setSearchParams = useCallback(
    (query?: Record<string, string> | URLSearchParams) => {
      if (!query) {
        history.push(pathname)
        return
      }

      let queryString = '';

      if (query instanceof URLSearchParams) {
        for (const [key, value] of query) {

          if (queryString) {
            queryString = queryString + '&' + key + '=' + value;
          } else {
            queryString = queryString + key + '=' + value;
          }
        }
      } else {
        for (const key in query) {
          if (queryString) {
            queryString = queryString + '&' + key + '=' + query[key];
          } else {
            queryString = queryString + key + '=' + query[key];
          }
        }

      }

      // history.push(pathname + '?' + queryString);
      history.replace(pathname + '?' + queryString);
    },
    [history, pathname]
  );

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  return [searchParams, setSearchParams];
};
