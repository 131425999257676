import { IAction, IProduct } from '@shared-components/models';
import {
  ADD_PRODUCT_FAILURE,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS
} from './product.constant';
import { CrmPaginatedResponse } from '@model/api-response.model';
import { ProductView } from '../../pages/products/product.model';

export const addProductRequest = (
  data: IProduct,
  triggerRefresh?: () => void
): IAction<IProduct> => ({
  type: ADD_PRODUCT_REQUEST,
  payload: data,
  triggerRefresh: triggerRefresh,
});

export const addProductSuccess = (payload: IProduct): IAction<IProduct> => ({
  type: ADD_PRODUCT_SUCCESS,
  payload,
});

export const addProductFailure = (payload: any): IAction => ({
  type: ADD_PRODUCT_FAILURE,
  payload,
});

export const getProductsRequest = (params: any): IAction<IProduct> => ({
  type: GET_PRODUCTS_REQUEST,
  payload: params,
});

export const getProductsSuccess = (payload: CrmPaginatedResponse<ProductView>): IAction<CrmPaginatedResponse<ProductView>> => ({
  type: GET_PRODUCTS_SUCCESS,
  payload,
});

export const getProductsFailure = (payload: any): IAction => ({
  type: GET_PRODUCTS_FAILURE,
  payload,
});
