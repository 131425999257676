import { IAction, IPayload, IUser } from '@shared-components/models';
import * as actionTypes from './user.constant';
import { IUserData } from 'libs/shared/src/crm-modules/user/user.model';

export const fetchUsers = (): IAction => ({
  type: actionTypes.GET_USERS_REQUEST,
});

export const fetchUsersSuccess = (payload: IUser[]): IAction<IUser[]> => ({
  type: actionTypes.GET_USERS_SUCCESS,
  payload,
});

export const fetchUsersFailure = (payload: IPayload<IUser>): IAction<any> => ({
  type: actionTypes.GET_USERS_FAILURE,
  payload,
});

export const addUserRequest = (
  payload: IUser,
  triggerRefresh: any
): IAction<IUser> => ({
  type: actionTypes.ADD_USER_REQUEST,
  payload,
  triggerRefresh,
});

export const addUserSuccess = (payload: IUser): IAction<IUser> => ({
  type: actionTypes.ADD_USER_SUCCESS,
  payload,
});

export const addUserFailure = (payload: IPayload<IUser>): IAction => ({
  type: actionTypes.ADD_USER_FAILURE,
  payload,
});

export const fetchUser = (payload: string): IAction<string> => ({
  type: actionTypes.GET_USER_REQUEST,
  payload,
});

export const fetchUserSuccess = (payload: IUser): IAction<IUser> => ({
  type: actionTypes.GET_USER_SUCCESS,
  payload,
});

export const fetchUserFailure = (payload: IPayload<IUser>): IAction => ({
  type: actionTypes.GET_USER_FAILURE,
  payload,
});

export const fetchFollowerUsers = (payload: string): IAction<string> => ({
  type: actionTypes.GET_FOLLOWER_USERS_REQUEST,
  payload,
});

export const fetchFollowerUsersSuccess = (
  payload: IUser[]
): IAction<IUser[]> => ({
  type: actionTypes.GET_FOLLOWER_USERS_SUCCESS,
  payload,
});

export const fetchFollowerUsersFailure = (
  payload: IPayload<IUser>
): IAction<any> => ({
  type: actionTypes.GET_FOLLOWER_USERS_FAILURE,
  payload,
});

export const clearUserFetch = (): IAction => ({
  type: actionTypes.CLEAR_USER_FETCH,
});

export const editUserAction = (
  payload: IUserData,
  triggerRefresh: any
): IAction<IUserData> => ({
  type: actionTypes.UPDATE_USER_REQUEST,
  payload,
  triggerRefresh,
});

export const editUserSuccess = (payload: IUserData): IAction<IUserData> => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
  payload,
});

export const editUserFailure = (payload: any): IAction => ({
  type: actionTypes.UPDATE_USER_FAILURE,
  payload,
});

export const deactivateUserRequest = (
  payload: IUser,
  triggerRefresh: any
): IAction<IUser> => ({
  type: actionTypes.DEACTIVATE_USER_REQUEST,
  payload,
  triggerRefresh,
});

export const deactivateUserSuccess = (payload: IUser): IAction<IUser> => ({
  type: actionTypes.DEACTIVATE_USER_SUCCESS,
  payload,
});

export const deactivateUserFailure = (payload: IUser): IAction<IUser> => ({
  type: actionTypes.DEACTIVATE_USER_FAILURE,
  payload,
});

export const activateUserRequest = (
  payload: string,
  triggerRefresh: any
) => ({
  type: actionTypes.ACTIVATE_USER_REQUEST,
  payload,
  triggerRefresh,
});

export const activateUserSuccess = (payload: IUser): IAction<IUser> => ({
  type: actionTypes.ACTIVATE_USER_SUCCESS,
  payload,
});

export const activateUserFailure = (payload: IUser): IAction<IUser> => ({
  type: actionTypes.ACTIVATE_USER_FAILURE,
  payload,
});
