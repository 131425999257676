import React from "react";
import { Card, Form, Input, Typography } from "antd";

const EnquiryViewAdditionalComments = ({
  editMode,
}: { editMode: boolean }) => {
  return (
    <Card className="margin-top-2" title={<Typography.Text strong>Additional Comments</Typography.Text>} headStyle={{ background: "none" }}>
      <Form.Item name="comment">
        <Input.TextArea className={!editMode ? "enquiry-view-form____form-container__text-area enquiry-view-form__cursor-default" : ""} rows={3} placeholder={!editMode ? "N/A" : ""} readOnly={!editMode} />
      </Form.Item>
    </Card>
  )
}

export { EnquiryViewAdditionalComments };
