import { Collapse } from 'antd';
import moment from 'moment';
import React from 'react';
import BranchPanel from './enquiryBranchActivityPanel';

const { Panel } = Collapse;

type Props = {
  name: string,
  createdAt: string,
  data: {
    currentPersonalInformation: any,
    previousPersonalInformation: any
  },
}

export const EnquiryPersonalInformationActivity: React.FC<Props> = ({
  name,
  createdAt,
  data,
}) => {

  const { firstNameChanged, lastNameChanged, emailChanged, branchIdChanged } = hasChanges(data.currentPersonalInformation, data.previousPersonalInformation);

  if (!firstNameChanged && !lastNameChanged && !branchIdChanged && !emailChanged) {
    return null;
  }
  return (
    <>
      {firstNameChanged && (
        <Panel
          style={{
            paddingLeft: 20, padding: 15, background: 'none',
          }}
          key={1}
          header={(
            <div className="enquiry-info">
              <p className="enquiry-author">
                <span style={{ fontWeight: 'bold', fontSize: '14px' }}>First Name </span> changed from   <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{data.previousPersonalInformation.personalInformation.firstName} </span>to <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{data.currentPersonalInformation.personalInformation.firstName} </span>
              </p>
              <div className="enquiry-date">
                <div>{moment(createdAt).format('dddd, MMMM D, YYYY')}</div>
                <div>by {name}</div>
              </div>
            </div >
          )}>
        </Panel >

      )}
      {
        lastNameChanged && (
          <Panel
            style={{
              paddingLeft: 20, padding: 15, background: 'none',
            }}
            key={1}
            header={(
              <div className="enquiry-info">
                <p className="enquiry-author">
                  <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Last Name </span> changed from   <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{data.previousPersonalInformation.personalInformation.lastName} </span>to <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{data.currentPersonalInformation.personalInformation.lastName} </span>
                </p>
                <div className="enquiry-date">
                  <div>{moment(createdAt).format('dddd, MMMM D, YYYY')}</div>
                  <div>by {name}</div>
                </div>
              </div>
            )}>
          </Panel>
        )
      }
      {
        emailChanged && (
          <Panel
            style={{
              paddingLeft: 20, padding: 15, background: 'none',
            }}
            key={1}
            header={(
              <div className="enquiry-info">
                <p className="enquiry-author">
                  <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Email</span> changed from   <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{data.previousPersonalInformation.personalInformation.email} </span>to <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{data.previousPersonalInformation.personalInformation.email} </span>
                </p>
                <div className="enquiry-date">
                  <div>{moment(createdAt).format('dddd, MMMM D, YYYY')}</div>
                  <div>by {name}</div>
                </div>
              </div>
            )}>
          </Panel>
        )
      }
      {
        branchIdChanged && (
          <>
            <BranchPanel
              previousBranchId={data.previousPersonalInformation.branchId}
              currentBranchId={data.currentPersonalInformation.branchId}
              name={name}
              createdAt={createdAt}
            />
          </>
        )
      }
    </>
  );
}

function hasChanges(currentDetails: any, previousDetails: any) {
  const firstNameChanged = currentDetails.personalInformation.firstName !== previousDetails.personalInformation.firstName;
  const lastNameChanged = currentDetails.personalInformation.lastName !== previousDetails.personalInformation.lastName;
  const emailChanged = currentDetails.personalInformation.email !== previousDetails.personalInformation.email;
  const branchIdChanged = currentDetails.branchId !== previousDetails.branchId;


  return {
    firstNameChanged,
    lastNameChanged,
    emailChanged,
    branchIdChanged,
  };
}
