import { JSONContent } from '@tiptap/react';

function extractMentions(content: JSONContent[] | undefined) {
  const mentions: string[] = [];
  const mentionContent: JSONContent[] = [];

  function traverse(item: JSONContent) {
    if (item?.type === 'mention') {
      mentions.push(JSON.parse(item.attrs?.id)?.id);
      mentionContent.push(item);
    }

    if (Array.isArray(item.content)) {
      item.content.forEach(traverse);
    }
  }

  if (Array.isArray(content)) {
    content.forEach(traverse);
  } else if (content) {
    traverse(content);
  }

  return {
    mentions: Array.from(new Set(mentions.filter(Boolean))),
    mentionContent,
  };
}

export default extractMentions;
