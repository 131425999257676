import { useAppSelector } from '@crm/core';
import {
  addEnglishTestScore,
  deleteEnglishTestScore,
  updateEnglishTestScore
} from '@crm/services.api';
import { RootState } from '@crm/src/core/store';
import { LANGUAGE_SCORE_RESPONSE } from '@moxie/constants';
import {
  errorNotificationHandler,
  successNotificationHandler
} from '@moxie/shared';
import {
  IAddEnglishTestScore,
  IUpdateEnglishTestScore
} from '@shared-components/models';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const usePteMutation = () => {
  const queryClient = useQueryClient();

  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);
  const contactData = useAppSelector(
    (state: RootState) => state?.contact?.singleData
  );

  return useMutation({
    mutationFn: (body: IAddEnglishTestScore) => addEnglishTestScore({ ...body, parentId: contactData.id }),
    onSuccess: ({ data }) => {
      successNotificationHandler(LANGUAGE_SCORE_RESPONSE.ADD_SUCCESS);
      queryClient.invalidateQueries({
        queryKey: ['englishTestScore'],
      });
      socket?.emit('server::profile-updated', {
        activitiesTypeId: contactData.id,
        activitiesType: 'language-test',
        activitiesAction: 'created',
        userId: user?.id,
        contactId: contactData?.id,
        companyId: user?.companyId as string,
        data: { testName: 'PTE' },
      });
    },
    onError: () => {
      errorNotificationHandler('Error adding PTE score.');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['pte'] });
    },
  });
};

export const usePteUpdate = () => {
  const queryClient = useQueryClient();
  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);
  const contactData = useAppSelector(
    (state: RootState) => state?.contact?.singleData
  );

  return useMutation({
    mutationFn: (body: IUpdateEnglishTestScore) => updateEnglishTestScore({ ...body, contactId: contactData.id }),
    onSuccess: async ({ data }) => {
      successNotificationHandler(LANGUAGE_SCORE_RESPONSE.UPDATE_SUCCESS);
      queryClient.invalidateQueries({
        queryKey: ['englishTestScore'],
      });
      socket?.emit('server::profile-updated', {
        activitiesTypeId: contactData.id,
        activitiesType: 'language-test',
        activitiesAction: 'updated',
        userId: user?.id,
        contactId: contactData?.id,
        companyId: user?.companyId as string,
        data: { testName: 'PTE' },
      });
    },
    onError: () => {
      errorNotificationHandler('Error updating PTE score.');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['updatePte'] });
    },
  });
};

export const usePteDelete = () => {
  const queryClient = useQueryClient();

  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);
  const contactData = useAppSelector(
    (state: RootState) => state?.contact?.singleData
  );

  return useMutation({
    mutationFn: (id: string) => deleteEnglishTestScore(id),
    onSuccess: async ({ data }) => {
      successNotificationHandler(LANGUAGE_SCORE_RESPONSE.DELETE_SUCCESS);
      queryClient.invalidateQueries({
        queryKey: ['englishTestScore'],
      });
      socket?.emit('server::profile-updated', {
        activitiesTypeId: contactData.id,
        activitiesType: 'language-test',
        activitiesAction: 'deleted',
        userId: user?.id,
        contactId: contactData?.id,
        companyId: user?.companyId as string,
        data: { testName: 'PTE' },
      });
    },
    onError: () => {
      errorNotificationHandler('Error deleting PTE score.');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['deletePte'] });
    },
  });
};
