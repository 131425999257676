import { getAllVisitActivityLogs } from "@crm/services.api"
import { CrmPaginatedResponse } from "@model/api-response.model"
import { OfficeVisitActivity } from "@model/office-visit"
import { useInfiniteQuery } from "@tanstack/react-query"


export const useFetchOfficeVisitActivities = (officeVisitId: string) => {
  const query = useInfiniteQuery<CrmPaginatedResponse<OfficeVisitActivity>>({
    enabled: !!officeVisitId,
    queryKey: ['office-visit-log', officeVisitId],
    queryFn: async ({ pageParam = 1 }) => {
      return getAllVisitActivityLogs(officeVisitId, {
        page: pageParam,
        limit: 3,
      });
    },
    getNextPageParam: (lastPage) =>
      lastPage.data.meta.currentPage === lastPage.data.meta.totalPages
        ? false
        : lastPage.data.meta.currentPage + 1,
  });

  return {
    activities: query.data?.pages.map((p) => p.data.data).flat() || [],
    hasMore: Boolean(query.hasNextPage),
    next: query.fetchNextPage,
    currentPage: query.data?.pages.at(-1)?.data.meta.currentPage,
    isLoading: query.isLoading,
  };
};

