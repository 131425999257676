import { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

export const useDebounced = (fn: any, delay = 200) => {
  const debounced = useMemo(() => debounce(fn, delay), []);

  useEffect(() => {
    return () => {
      debounced.cancel();
    };
  }, []);
  return debounced;
};

export const useDebouncedValue = <T>(value: T, delay = 200) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedValue(value);
    }, delay);

    handler();

    return () => {
      handler.cancel();
    };
  }, [value, delay]);

  return debouncedValue;

}
