import axios from './axios';

import { CancelToken } from 'axios';
import { API_URL } from '@moxie/constants';
import {
  IAddEnglishTestScore,
  IContactCrm,
  ICrmContactImport,
  ICrmEducation,
  ICrmEducationBgLevel,
  IEducation,
  IOfficeBranch,
  IOfficeBranchCRM,
  IProfessionalExperience,
  IResponse,
  IUpdateEnglishTestScore,
  IUser
} from '@shared-components/models';
import crmAxios from './crm-api-axios';
import { CrmPaginatedResponse } from '@model/api-response.model';
import { IUserData } from 'libs/shared/src/crm-modules/user/user.model';
import { ContactActivity } from '@model/contact-activity';
import { Assignee } from '@model/application';

const getcontactList = async (params: Record<string, unknown>): Promise<CrmPaginatedResponse<IContactCrm>> => {
  return (await crmAxios.get(`${API_URL.CRM_API_CONTACT}`, {
    params: {
      ...params,
    },
  }))?.data;
};

const updateContact = async (
  id: string,
  data: Partial<IContactCrm>
): Promise<IContactCrm> => {
  return crmAxios.put(`${API_URL.CRM_API_CONTACT}/${id}`, data);
};

const getArchivedContact = (params = '', cancelToken?: CancelToken): Promise<any> => {
  return axios.get(`${API_URL.CRM_CONTACT}/archived?${params}`, { cancelToken });
};

const checkLeadEmail = (email: string): Promise<any> => {
  return crmAxios.get(`${API_URL.URL_CRM_ADD_LEAD}/check-email/${email}`);
};

const getContactDetails = async (id: string): Promise<any> => {
  return (await crmAxios.get(`${API_URL.CRM_API_CONTACT}/${id}`))?.data;
};

const updateCrmContactAssignee = (data: any): Promise<any> => {
  const id = data.id;
  delete data.id;
  return crmAxios.put(`${API_URL.CRM_API_CONTACT}/${id}`, data);
};

const updateCrmContactFollowers = async (data: any): Promise<any> => {
  const id = data.id;
  delete data.id;
  const response = await crmAxios.put(`${API_URL.CRM_API_CONTACT}/${id}`, data);
  return response;
};

const addContactEducationBackground = async (body: any): Promise<any> => {
  return crmAxios.post(`${API_URL.CRM_API_EDUCATION_BACKGROUNDS}`, body);
};

const getContactEducationBackground = async (id: string): Promise<CrmPaginatedResponse<ICrmEducation>> => {
  return (await crmAxios.get(`${API_URL.CRM_API_EDUCATION_BACKGROUNDS}/by-contact/${id}`))?.data;
};

const updateContactEducationBackground = (
  body: IEducation
): Promise<IResponse> => {
  return crmAxios.put(
    `${API_URL.CRM_API_EDUCATION_BACKGROUNDS}/${body.id}`,
    body
  );
};

const deleteContactEducationBackground = async (
  educationBackgroundId: string
): Promise<IResponse> => {
  return crmAxios.delete(
    `${API_URL.CRM_API_EDUCATION_BACKGROUNDS}/${educationBackgroundId}`
  );
};

const getContactEducationLevels = async (): Promise<ICrmEducationBgLevel[]> => {
  return (
    await crmAxios.get(`${API_URL.CRM_API_EDUCATION_BACKGROUNDS}/degree-levels`)
  )?.data?.data;
};

const addContactProfessionalExperience = async (
  body: IProfessionalExperience
): Promise<IResponse> => {
  return crmAxios.post(`${API_URL.CRM_API_PROFESSIONAL_EXPERIENCE}`, body);
};

const getAllContactProfessionalExperience = async (
  contactId: string
): Promise<IResponse> => {
  return (
    await crmAxios.get(
      `${API_URL.CRM_API_PROFESSIONAL_EXPERIENCE}/by-contact/${contactId}`
    )
  )?.data;
};

const updateContactProfessionalExperience = async (
  body: any
): Promise<IResponse> => {
  const { id } = body;
  delete body.id;
  return crmAxios.put(`${API_URL.CRM_API_PROFESSIONAL_EXPERIENCE}/${id}`, body);
};

const deleteContactProfessionalExperience = async (
  body: any
): Promise<IResponse> => {
  const { professionalExperienceId } = body;
  const response = await crmAxios.delete(
    `${API_URL.CRM_API_PROFESSIONAL_EXPERIENCE}/${professionalExperienceId}`
  );

  return response;
};

const addEnglishTestScore = async (
  body: IAddEnglishTestScore
): Promise<IResponse> => {
  const response = await crmAxios.post(
    `${API_URL.CRM_API_CONTACT_LANG_TEST_SCORE}`,
    body
  );
  return response;
};

const updateEnglishTestScore = async (
  body: IUpdateEnglishTestScore
): Promise<IResponse> => {
  const response = await crmAxios.put(
    `${API_URL.CRM_API_CONTACT_LANG_TEST_SCORE}/${body.id}`,
    body
  );
  return response;
};

const getEnglishTestScore = async (contactId: string): Promise<IResponse> => {
  return (
    await crmAxios.get(
      `${API_URL.CRM_API_CONTACT_LANG_TEST_SCORE}/by-contact/${contactId}`
    )
  )?.data;
};

const deleteEnglishTestScore = async (id: string) => {
  return crmAxios.delete(`${API_URL.CRM_API_CONTACT_LANG_TEST_SCORE}/${id}`);
};

const getContactBranches = async (
  id: string
): Promise<CrmPaginatedResponse<IOfficeBranchCRM>> => {
  return (await crmAxios.get(`${API_URL.CRM_API_OFFICE}/query`, {
    params: {
      'filter.contact': id,
    },
  }))
};

const fetchContactApplicationAssignees = async (id: string): Promise<Assignee[]> => {
  const {
    data: { data },
  } = await crmAxios.get<any>(
    `${API_URL.CRM_API_CONTACT}/${id}/application-assignees`
  );
  return data;
};

const fetchPossibleUsersInContact = async (
  contactId?: string,
  branch?: IOfficeBranch
): Promise<IResponse<IUserData[]>> => {
  const { data } = await crmAxios.get(`${API_URL.CRM_API_USER}/assignees`, {
    params: {
      contactId,
      branch,
    },
  });
  return data;
};

const fetchUsersInContact = async (): Promise<IUserData[]> => {
  const { data } = await crmAxios.get(`${API_URL.CRM_API_CONTACT_ASSIGNEES}`);
  return data.data;
};

const fetchContactCountries = async (): Promise<string[]> => {
  const { data } = await crmAxios.get(`${API_URL.CRM_API_CONTACT_COUNTRIES}`);
  return data.data;
};

const fetchContactPassportCountries = async (): Promise<string[]> => {
  const { data } = await crmAxios.get(`${API_URL.CRM_API_COUNTRY_PASSPORT}`);
  return data.data;
};

const fetchContactStates = async (): Promise<string[]> => {
  const { data } = await crmAxios.get(`${API_URL.CRM_API_CONTACT_STATES}`);
  return data.data;
};

const updateBranches = async (contactId: string, branches: { id: string }[]) => {
  return await crmAxios.put<IResponse<IContactCrm>>(
    `${API_URL.CRM_API_CONTACT}/${contactId}`,
    { secondaryBranches: branches }
  );
};

const fetchContactApplicationActivities = async (
  params: Record<string, unknown>
) => {
  return (
    await crmAxios.get<CrmPaginatedResponse<ContactActivity>>(
      `${API_URL.CRM_CONTACT_ACTIVITIES}/`, { params }
    )
  ).data;
};

const canViewContactStatus = async (contactId: string) => {
  return (
    await crmAxios.get(`${API_URL.CRM_API_CONTACT}/${contactId}/can-view`)
  ).data?.data;
};

const getContactsReport = async (query: any) => {
  const response = await crmAxios.get(
    `${API_URL.CRM_CONTACTS_REPORTS}?${query}`
  );
  return response;
};

const fetchContactAssignees = () =>
  axios.get<{ users: IUser[] }>(`${API_URL.CRM_CONTACT}/assignees`);

const fetchContactBranches = () => crmAxios.get<{ data: IOfficeBranchCRM[] }>(`${API_URL.CRM_API_CONTACT}/branches`);

const verifyEmailExists = async (emails: string[]) => {
  const {
    data: { data },
  } = await crmAxios.post<{ data: { isDeleted: boolean, email: string }[] }>(
    `${API_URL.CRM_API_CONTACT}/verify-emails`,
    { emails }
  );
  return data;
};

const importContacts = async (body: ICrmContactImport[]) => {
  return crmAxios.post(`${API_URL.CRM_API_CONTACT}/import`, body);
};

export {
  getcontactList,
  getArchivedContact,
  checkLeadEmail,
  getContactDetails,
  updateCrmContactAssignee,
  updateCrmContactFollowers,
  addContactEducationBackground,
  getContactEducationBackground,
  updateContactEducationBackground,
  deleteContactEducationBackground,
  getContactEducationLevels,
  addContactProfessionalExperience,
  getAllContactProfessionalExperience,
  updateContactProfessionalExperience,
  deleteContactProfessionalExperience,
  addEnglishTestScore,
  getEnglishTestScore,
  updateEnglishTestScore,
  deleteEnglishTestScore,
  getContactBranches,
  fetchContactApplicationActivities,
  updateBranches,
  fetchPossibleUsersInContact,
  fetchUsersInContact,
  fetchContactApplicationAssignees,
  canViewContactStatus,
  getContactsReport,
  fetchContactAssignees,
  fetchContactPassportCountries,
  fetchContactCountries,
  fetchContactStates,
  fetchContactBranches,
  verifyEmailExists,
  importContacts,
  updateContact
};
